import axios from "axios";

import {
  GET_HOST,
  GET_FAKE_HOST,
  CREATE_MULTIPLE_HOST,
  EDIT_FAKE_HOST,
  BLOCK_UNBLOCK_HOST,
  CREATE_NEW_HOST,
  EDIT_HOST,
  CLOSE_HOST_DIALOG,
  SET_CREATE_HOST_DONE,
  SET_UPDATE_HOST_DONE,
  GET_HOST_ANALYTIC,
  GET_TOTAL_COIN_OF_ANALYTIC,
  GET_LIVE_STREAMING_ANALYTIC,
  GET_LIVE_STREAMING_COIN,
  EXTRA_BONUS,
  GET_AGENCY_WISE_HOST,
  GET_HOST_SCREENSHOT,
  GET_HOST_FACE_RECOGNIZE_VIDEO,
  GET_ANCHOR_REPORT,
  GET_HOST_SETTLEMENT_HISTORY,
  LIVE,
  DELETE_FAKE_HOST,
  VIDEO,
  GET_HOST_WEEK_WISE_SETTLEMENT_HISTORY,
} from "./types";

export const hostList = (agencyId, type, startDate, endDate) => (dispatch) => {
  axios
    .get(
      agencyId === null
        ? `/host?type=${type}&startDate=${startDate}&endDate=${endDate}`
        : `/host?agencyId=${agencyId}&type=${type}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => {
      dispatch({ type: GET_HOST, payload: res.data.data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const FakeHostList = () => (dispatch) => {
  axios
    .get("/fakeHost")
    .then((res) => {
      dispatch({ type: GET_FAKE_HOST, payload: res.data.data });
    })
    .catch((err) => {
      console.log(err);
    });
};

//Get Host Settlement History Report
export const getHostSettlementHistoryReport =
  (agencyId, type, startDate, endDate) => (dispatch) => {
    axios
      .get(
        `/settlementHistory?agencyId=${agencyId}&type=${type}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        dispatch({ type: GET_HOST_SETTLEMENT_HISTORY, payload: res.data.data });
      })
      .catch((error) => console.log(error));
  };

export const getAgencyWiseHost = () => (dispatch) => {
  axios
    .get("/host/agencyWiseHostCount")
    .then((res) => {
      dispatch({ type: GET_AGENCY_WISE_HOST, payload: res.data.data });
    })
    .catch((error) => console.log(error));
};
export const getHost = (agencyId) => (dispatch) => {
  axios
    .get(`/host/agency/${agencyId}`)
    .then((res) => {
      dispatch({ type: GET_HOST, payload: res.data.data });
    })
    .catch((error) => console.log(error));
};

export const blockUnblockHost = (id) => (dispatch) => {
  axios
    .get(`/host/blockUnblock/${id}`)
    .then((res) => {
      dispatch({ type: BLOCK_UNBLOCK_HOST, payload: res.data.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const extraBonus = (id) => (dispatch) => {
  axios
    .get(`/host/bonus/${id}`)
    .then((res) => {
      dispatch({ type: EXTRA_BONUS, payload: res.data.data });
    })
    .catch((error) => console.log(error));
};

//for fake host
export const live = (id) => (dispatch) => {
  axios
    .patch(`/fakeHost/${id}`)
    .then((res) => {
      dispatch({ type: LIVE, payload: res.data.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

//for fake host
export const video = (id) => (dispatch) => {
  axios
    .put(`/fakeHost/${id}`)
    .then((res) => {
      dispatch({ type: VIDEO, payload: res.data.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

// export const createNewHost = (formData) => (dispatch) => {
//   axios
//     .post("/host", formData)
//     .then((res) => {
//       dispatch({ type: CREATE_NEW_HOST, payload: res.data.host });
//       dispatch({ type: CLOSE_HOST_DIALOG });
//       dispatch({ type: SET_CREATE_HOST_DONE });
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

export const createNewHost = (formData) => (dispatch) => {
  axios
    .post("/fakeHost", formData)
    .then((res) => {
      dispatch({ type: CREATE_NEW_HOST, payload: res.data.fakeHost });
      dispatch({ type: CLOSE_HOST_DIALOG });
      dispatch({ type: SET_CREATE_HOST_DONE });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const createMultipleHost = (formData) => (dispatch) => {
  axios
    .post("/fakeHost/multiple", formData)
    .then((res) => {
      dispatch({ type: CREATE_MULTIPLE_HOST, payload: res.data.fakeHost });
      dispatch({ type: CLOSE_HOST_DIALOG });
      dispatch({ type: SET_CREATE_HOST_DONE });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const editHost = (formData, id) => (dispatch) => {
  axios
    .patch(`/host/${id}`, formData)
    .then((res) => {
      console.log("--------", res.data);
      dispatch({
        type: EDIT_HOST,
        payload: { data: res.data.host, id },
      });
      dispatch({ type: CLOSE_HOST_DIALOG });
      dispatch({ type: SET_UPDATE_HOST_DONE });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const editFakeHost = (formData, id) => (dispatch) => {
  axios
    .patch(`/fakeHost?hostId=${id}`, formData)
    .then((res) => {
      console.log("--------", res.data);
      dispatch({
        type: EDIT_FAKE_HOST,
        payload: { data: res.data.data, id },
      });
      dispatch({ type: CLOSE_HOST_DIALOG });
      dispatch({ type: SET_UPDATE_HOST_DONE });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const hostAnalytic = (id, start, end) => (dispatch) => {
  axios
    .get(`/host/analytic/${id}?start=${start}&end=${end}`)
    .then((res) => {
      dispatch({ type: GET_HOST_ANALYTIC, payload: res.data.data });
      dispatch({
        type: GET_TOTAL_COIN_OF_ANALYTIC,
        payload: res.data.totalCoin,
      });
    })
    .catch((error) => console.log(error));
};

export const anchorReport = (id, type) => (dispatch) => {
  axios
    .get(`/host/report/?hostId=${id}&type=${type}`)
    .then((res) => {
      dispatch({ type: GET_ANCHOR_REPORT, payload: res.data.report });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const liveStreamingAnalytic = (id, start, end) => (dispatch) => {
  axios
    .get(`/host/analytic/liveStreaming/${id}?start=${start}&end=${end}`)
    .then((res) => {
      dispatch({ type: GET_LIVE_STREAMING_ANALYTIC, payload: res.data.data });
      dispatch({
        type: GET_LIVE_STREAMING_COIN,
        payload: res.data.totalCoin,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

//Get Host Work Screenshot
export const getHostWorkScreenshot = (hostId) => (dispatch) => {
  axios
    .get(`screenshot/${hostId}`)
    .then((res) => {
      dispatch({ type: GET_HOST_SCREENSHOT, payload: res.data });
    })
    .catch((error) => console.log(error));
};

//Get Host face recognize
export const getHostFaceRecognize = (hostId) => (dispatch) => {
  axios
    .get(`request/getRequestByUserId?hostId=${hostId}`)
    .then((res) => {
      dispatch({
        type: GET_HOST_FACE_RECOGNIZE_VIDEO,
        payload: res.data.request,
      });
    })
    .catch((error) => console.log(error));
};

// delete fake host

export const deleteFakeHost = (id) => (dispatch) => {
  axios
    .delete(`/fakeHost/${id}`)
    .then((res) => {
      dispatch({ type: DELETE_FAKE_HOST, payload: id });
    })
    .catch((error) => console.log(error));
};

//  host week wise settlement

export const getHostWeekWiseSettlement = (id) => (dispatch) => {
  axios
    .get(`settlementHistory/getAgencyWiseAllSettlemet?agencyId=${id}`)
    .then((res) => {
      console.log("res.data", res.data);
      dispatch({
        type: GET_HOST_WEEK_WISE_SETTLEMENT_HISTORY,
        payload: res.data.history,
      });
    })
    .catch((error) => console.log("error", error));
};
