import axios from "axios";

import {
  GET_PURCHASE_COIN_HISTORY,
  GET_OFFLINE_RECHARGE_HISTORY,
} from "./types";

export const getPurchaseCoinHistory =
  (type, startDate, endDate) => (dispatch) => {
    axios
      .get(
        `/history/admin/history?type=${type}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: GET_PURCHASE_COIN_HISTORY,
          payload: res.data.history,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

//Get offline recharge history
export const getOfflineRechargeHistory = () => (dispatch) => {
  axios
    .get(`/history/getOfflineHistory`)
    .then((res) => {
      dispatch({
        type: GET_OFFLINE_RECHARGE_HISTORY,
        payload: res.data.history,
      });
    })
    .catch((error) => console.log(error));
};
