import React, { useState, useEffect } from "react";

//router
import { Link } from "react-router-dom";



//redux
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getSetting,
  editSetting,
  handleGoogleSwitch,
  handleRazorSwitch,
  handleStripeSwitch,
  handlePhoneSwitch,
} from "../store/setting/action";

//axios
import axios from "axios";

//argon css
import "./css/Profile.css";

//MUI
import IOSSwitch from "@material-ui/core/Switch";
import {
  Chip,
  Input,
  makeStyles,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { calendarFormat } from "moment";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const gateway = ["Paytm", "Paypal", "Google pay", "Amazone"];

const SettingPage = (props) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [redeemGateway, setRedeemGateway] = useState([]);

  const handleChange = (event) => {
    setRedeemGateway(event.target.value);
  };

  const [mongoId, setMongoId] = useState(null);
  const [googlePayId, setGooglePayId] = useState(null);
  const [agoraCertificate, setAgoraCertificate] = useState(null);
  const [agoraId, setAgoraId] = useState(null);
  const [policyLink, setPolicyLink] = useState(null);
  const [termOfService, setTermOfService] = useState(null);
  const [hostCharge, setHostCharge] = useState(null);
  const [minPoints, setMinPoints] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [howManyCoins, setHowManyCoins] = useState(null);
  const [toCurrency, setToCurrency] = useState(null);
  const [userCallCharge, setUserCallCharge] = useState(null);
  const [userLiveStreamingCharge, setUserLiveStreamingCharge] = useState(null);
  const [dailyTaskMinValue, setDailyTaskMinValue] = useState(null);
  const [dailyTaskMaxValue, setDailyTaskMaxValue] = useState(null);
  const [stripeSecreteKey, setStripeSecreteKey] = useState(null);
  const [stripePublishableKey, setStripePublishableKey] = useState(null);
  const [phonePayId, setPhonePayId] = useState(null);
  const [razorPayId, setRazorPayId] = useState(null);
  const [redeemDay, setRedeemDay] = useState(0);
  const [chatCharge, setChatCharge] = useState(0);
  const [addCoinInHost, setAddCoinInHost] = useState(0);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loginMessage, setLoginMessage] = useState(null);
  const [callBonus1, setCallBonus1] = useState(0);
  const [callBonus2, setCallBonus2] = useState(0);
  const [callBonus3, setCallBonus3] = useState(0);
  const [callBonus4, setCallBonus4] = useState(0);
  const [callBonus5, setCallBonus5] = useState(0);
  const [callDuration1, setCallDuration1] = useState(0);
  const [callDuration2, setCallDuration2] = useState(0);
  const [callDuration3, setCallDuration3] = useState(0);
  const [callDuration4, setCallDuration4] = useState(0);
  const [callDuration5, setCallDuration5] = useState(0);
  const [stripeSwitch_ ,setStripeSwitch]=useState(false)
  const [googlePaySwitch ,setGooglePaySwitch]=useState(false)
  const [phonePaySwitch ,setPhonePaySwitch]=useState(false)
  const [razorPaySwitch ,setRazorPaySwitch]=useState(false)
  const [loginBonus, setLoginBonus] = useState(0);
  const [bonus, setBonus] = useState(0);

  const [error, setError] = useState({
    googlePayId: "",
    callBouns: "",
    callDuration: "",
    bonus:"",
    agoraId: "",
    policyLink: "",
    termOfService: "",
    loginBonus: "",
    hostCharge: "",
    redeemGateway: "",
    minPoints: "",
    currency: "",
    howManyCoins: "",
    toCurrency: "",
    userCallCharge: "",
    // userLiveStreamingCharge: "",
    dailyTaskMinValue: "",
    dailyTaskMaxValue: "",
    stripeSecreteKey: "",
    phonePayId: "",
    razorPayId:"",
    stripePublishableKey: "",
    agoraCertificate: "",
    addCoinInHost: "",
    // chatCharge: "",
  });

  const setting = useSelector((state) => state.setting?.setting);

  useEffect(() => {
    props.getSetting();
  }, []);

 

  useEffect(() => {
    console.log('bonus', setting?.bonus)
    setStripeSwitch(setting?.stripeSwitch)
    setGooglePaySwitch(setting?.googlePaySwitch)
    setPhonePaySwitch(setting?.phonePaySwitch)
    setRazorPaySwitch(setting?.razorPaySwitch)
    setLoginBonus(setting?.loginBonus);
    setBonus(setting?.bonus);
    setCallDuration1(setting?.callDuration1);
    setCallDuration2(setting?.callDuration2);
    setCallDuration3(setting?.callDuration3);
    setCallDuration4(setting?.callDuration4);
    setCallDuration5(setting?.callDuration5);
    setAddCoinInHost(setting?.addCoinInHost);
    setMongoId(setting?._id);
    setGooglePayId(setting?.googlePayId);
    setAgoraId(setting?.agoraId);
    setAgoraCertificate(setting?.agoraCertificate);
    setPolicyLink(setting?.policyLink);
    setTermOfService(setting?.termOfService);
    setCallBonus1(setting?.callBouns1);
    setCallBonus2(setting?.callBouns2);
    setCallBonus3(setting?.callBouns3);
    setCallBonus4(setting?.callBouns4);
    setCallBonus5(setting?.callBouns5);
    setHostCharge(setting?.hostCharge);
    setRedeemGateway(setting?.redeemGateway?.split(",") || []);
    setMinPoints(setting?.minPoints);
    setCurrency(setting?.currency);
    setHowManyCoins(setting?.howManyCoins);
    setToCurrency(setting?.toCurrency);
    setUserCallCharge(setting?.userCallCharge);
    setUserLiveStreamingCharge(setting?.userLiveStreamingCharge);
    setDailyTaskMinValue(setting?.dailyTaskMinValue);
    setDailyTaskMaxValue(setting?.dailyTaskMaxValue);
    setStripeSecreteKey(setting?.stripeSecreteKey);
    setStripePublishableKey(setting?.stripePublishableKey);
    setPhonePayId(setting?.phonePayId);
    setRazorPayId(setting?.razorPayId);
    setRedeemDay(setting?.redeemDay);
    setChatCharge(setting?.chatCharge);
    setLoginMessage(setting?.loginMessage);
  }, [setting]);

  const handleSubmit = (e) => {
    e.preventDefault();
    

    if (redeemGateway.length === 0) {
      return setError({
        ...error,
        redeemGateway: "Please Select Atleast One Redeem Gateway!",
      });
    }

    if (
      !callDuration1 ||
      !callDuration2 ||
      !callDuration3 ||
      !callDuration4 ||
      !callDuration4 ||
      !addCoinInHost ||
      !googlePayId ||
      !agoraId ||
      !agoraCertificate ||
      !policyLink ||
      !termOfService ||
      !callBonus1 ||
      !callBonus2 ||
      !callBonus3 ||
      !callBonus4 ||
      !callBonus5 ||
      !redeemGateway ||
      !minPoints ||
      !currency ||
      !howManyCoins ||
      !toCurrency ||
      !userCallCharge ||
      // !userLiveStreamingCharge ||
      !dailyTaskMinValue ||
      !dailyTaskMaxValue ||
      !stripePublishableKey ||
      !stripeSecreteKey ||
      !phonePayId
      // !chatCharge
    ) {
      const errors = {};

      if (!addCoinInHost) errors.addCoinInHost = "Add Coin Host Required ! ";

      if (!callBonus1) errors.callBouns = "Call Bouns Required !";
      if (!callBonus2) errors.callBouns = "Call Bouns Required !";
      if (!callBonus3) errors.callBouns = "Call Bouns Required !";
      if (!callBonus4) errors.callBouns = "Call Bouns Required !";
      if (!callDuration1) errors.callDuration1 = "Call Duration1 Required !";
      if (!callDuration2) errors.callDuration2 = "Call Duration2 Required !";
      if (!callDuration3) errors.callDuration3 = "Call Duration3 Required !";
      if (!callDuration4) errors.callDuration4 = "Call Duration4 Required !";
      if (!callDuration5) errors.callDuration5 = "Call Duration5 Required !";
      if (!googlePayId) errors.googlePayId = "Google Pay Id is Required!";
      if (!agoraId) errors.agoraId = "Zego Sign In is Required!";
      if (!agoraCertificate)
        errors.agoraCertificate = "Zego App Id is Required!";
      if (!policyLink) errors.policyLink = "Policy Link is Required!";
      if (!termOfService) errors.termOfService = "Term of Service is Required!";
      if (!callBonus1) errors.callBonus1 = "Call Bonus1 is Required!";
      if (!callBonus2) errors.callBonus2 = "Call Bonus2 is Required!";
      if (!callBonus3) errors.callBonus3 = "Call Bonus3 is Required!";
      if (!callBonus4) errors.callBonus4 = "Call Bonus4 is Required!";
      if (!callBonus5) errors.callBonus5 = "Call Bonus5 is Required!";
      if (!hostCharge) errors.hostCharge = "Coin is Required!";
      if (!loginBonus) errors.loginBonus = "Login Bonus is Required!";
      if (!loginBonus) errors.loginBonus = " Bonus is Required!";
      if (!stripePublishableKey)
        errors.stripePublishableKey = "Stripe Publishable Key is Required!";

      if (!minPoints)
        errors.minPoints = "Minimum Points to Redeem is Required!";
      if (currency == "Currency") errors.currency = "Please Select a Currency!";
      if (!howManyCoins) errors.howManyCoins = "How Many Coins is Required!";
      if (!toCurrency) errors.toCurrency = "To Currency is Required!";
      if (!userCallCharge)
        errors.userCallCharge = "User Call Charge is Required!";
      // if (!userLiveStreamingCharge)
      //   errors.userLiveStreamingCharge =
      //     "User Live Streaming Value is Required!";
      if (!dailyTaskMinValue)
        errors.dailyTaskMinValue = "Minimum Value is Required!";
      if (!dailyTaskMaxValue)
        errors.dailyTaskMaxValue = "Maximum Value is Required!";
      if (!stripeSecreteKey)
        errors.stripeSecreteKey = "Stripe Secrete Key is Required!";
      if (!phonePayId) errors.phonePayId = "Phone Pay id is Required!";
      // if (!chatCharge) errors.chatCharge = "Chat Charge is Required!";

      return setError({ ...errors });
    }

    setError({ ...error, redeemGateway: "" });
    const data = {
      loginBonus,
      callDuration1,
      callDuration2,
      callDuration3,
      callDuration4,
      callDuration5,
      googlePayId,
      agoraId,
      agoraCertificate,
      policyLink,
      termOfService,
      callBouns1: callBonus1,
      callBouns2: callBonus2,
      callBouns3: callBonus3,
      callBouns4: callBonus4,
      callBouns5: callBonus5,
      hostCharge,
      redeemGateway: redeemGateway.join(","),
      minPoints,
      currency,
      howManyCoins,
      toCurrency,
      userCallCharge,
      userLiveStreamingCharge,
      dailyTaskMinValue,
      dailyTaskMaxValue,
      phonePayId,
      stripeSecreteKey,
      stripePublishableKey,
      redeemDay,
      bonus,
      chatCharge,
      loginMessage,
      addCoinInHost,
    };

    props.editSetting(data, mongoId);

    setOpenSuccess(true);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

const razorSwitch = () => {
  
  props.handleRazorSwitch(mongoId);
}

  const googleSwitch_ = () => {
    
    props.handleGoogleSwitch(mongoId);
  };
  const phoneSwtich = () => {
    
    props.handlePhoneSwitch(mongoId);
  };
  const stripeSwitch = () => {
    
    props.handleStripeSwitch(mongoId);
  };

  const day = [
    { name: "Sunday", value: 0 },
    { name: "Monday", value: 1 },
    { name: "Tuesday", value: 2 },
    { name: "Wednesday", value: 3 },
    { name: "Thursday", value: 4 },
    { name: "Friday", value: 5 },
    { name: "Saturday", value: 6 },
  ];





  return (
    <div class="main-content mt-5" id="panel">
      <Snackbar
        open={openSuccess}
        autoHideDuration={2000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSuccess} severity="success">
          Updated successfully.
        </Alert>
      </Snackbar>
      {/* <!-- Page content --> */}
      <div class="page-breadcrumb pt-1 mb-4">
        <div class="row">
          <div class="col-7 align-self-center">
            <div class="d-flex align-items-center">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0 p-0">
                  <li class="breadcrumb-item">
                    <Link to="/admin/dashboard" class="text-muted">
                      Home
                    </Link>
                  </li>
                  <li
                    class="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Setting
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt--6">
        <div class="row">
          <div class="col-xl-6 order-xl-2">
            <div class="card card-profile">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Login Bonus </h3>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <form>
                  {/* <h6 class="heading-small text-muted mb-4">
                    User information
                  </h6> */}
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label
                            class="form-control-label"
                            for="input-username"
                          >
                            Coin
                          </label>
                          <input
                            type="number"
                            id="input-username"
                            class="form-control"
                            value={loginBonus}
                            onChange={(e) => {
                              setLoginBonus(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  loginBonus: "Login Bonus is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  loginBonus: "",
                                });
                              }
                            }}
                          />
                          {error.loginBonus && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.loginBonus}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label
                            class="form-control-label"
                            for="input-username"
                          >
                            Bonus ( Admin to host )
                          </label>
                          <input
                            type="number"
                            id="input-username"
                            class="form-control"
                            value={bonus}
                            onChange={(e) => {
                              setBonus(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label
                            class="form-control-label"
                            for="input-username"
                          >
                            Login Message
                          </label>
                          <input
                            type="text"
                            id="input-username"
                            class="form-control"
                            placeholder="Enter Login Message Here..."
                            value={loginMessage}
                            onChange={(e) => {
                              setLoginMessage(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <a
                        href="#!"
                        class="btn btn-default float-right"
                        onClick={handleSubmit}
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-xl-6 order-xl-1">
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Set Id & Certificate </h3>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <form>
                  {/* <h6 class="heading-small text-muted mb-4">
                    User information
                  </h6> */}
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label
                            class="form-control-label"
                            for="input-username"
                          >
                            Zego Sign In
                          </label>
                          <input
                            type="text"
                            id="input-username"
                            class="form-control"
                            value={agoraId}
                            onChange={(e) => {
                              setAgoraId(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  agoraId: "Zego Sign In is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  agoraId: "",
                                });
                              }
                            }}
                          />
                          {error.agoraId && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.agoraId}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label
                            class="form-control-label"
                            for="input-username"
                          >
                            Zego App Id
                          </label>
                          <input
                            type="text"
                            id="input-username"
                            class="form-control"
                            value={agoraCertificate}
                            onChange={(e) => {
                              setAgoraCertificate(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  agoraCertificate: "Zego App Id is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  agoraCertificate: "",
                                });
                              }
                            }}
                          />
                          {error.agoraCertificate && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.agoraCertificate}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label
                            class="form-control-label"
                            for="input-username"
                          >
                            Privacy policy link
                          </label>
                          <input
                            type="text"
                            id="input-username"
                            class="form-control"
                            value={policyLink}
                            onChange={(e) => {
                              setPolicyLink(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  policyLink: "Policy Link is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  policyLink: "",
                                });
                              }
                            }}
                          />
                          {error.policyLink && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.policyLink}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label
                            class="form-control-label"
                            for="input-username"
                          >
                            Term Of Service
                          </label>
                          <input
                            type="text"
                            id="input-username"
                            class="form-control"
                            value={termOfService}
                            onChange={(e) => {
                              setTermOfService(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  termOfService: "Term Of Service is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  termOfService: "",
                                });
                              }
                            }}
                          />
                          {error.termOfService && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.termOfService}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <a
                        href="#!"
                        class="btn btn-info float-right"
                        onClick={handleSubmit}
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mt--6">
        <div class="row">
          <div class="col-xl-6 order-xl-2">
            <div class="card card-profile">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Charges for User & Host </h3>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <form>
                  {/* <h6 class="heading-small text-muted mb-4">
                    User information
                  </h6> */}
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label
                            class="form-control-label"
                            for="input-username"
                          >
                            Video call charge from User
                          </label>
                          <input
                            type="number"
                            id="input-username"
                            class="form-control"
                            value={userCallCharge}
                            onChange={(e) => {
                              setUserCallCharge(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  userCallCharge:
                                    "User Call Charge Value is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  userCallCharge: "",
                                });
                              }
                            }}
                          />
                          {error.userCallCharge && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.userCallCharge}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="form-group">
                          <label
                            class="form-control-label"
                            for="input-username"
                          >
                            Add Coin Host(second)
                          </label>
                          <input
                            type="number"
                            id="input-username"
                            class="form-control"
                            value={addCoinInHost}
                            onChange={(e) => {
                              setAddCoinInHost(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  addCoinInHost: "Coin is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  addCoinInHost: "",
                                });
                              }
                            }}
                          />
                          {error.addCoinInHost && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.addCoinInHost}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* <div class="col-lg-12">
                        <div class="form-group">
                          <label
                            class="form-control-label"
                            for="input-username"
                          >
                            Live Streaming Charge from Host
                          </label>
                          <input
                            type="number"
                            id="input-username"
                            class="form-control"
                            value={userLiveStreamingCharge}
                            onChange={(e) => {
                              setUserLiveStreamingCharge(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  userLiveStreamingCharge:
                                    "User Live streaming Charge Value is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  userLiveStreamingCharge: "",
                                });
                              }
                            }}
                          />
                          {error.userLiveStreamingCharge && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.userLiveStreamingCharge}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <a
                        href="#!"
                        class="btn btn-primary float-right"
                        onClick={handleSubmit}
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-xl-6 order-xl-1">
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Daily Task Limit </h3>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <form>
                  {/* <h6 class="heading-small text-muted mb-4">
                    User information
                  </h6> */}
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label
                            class="form-control-label"
                            for="input-username"
                          >
                            Minimum Value
                          </label>
                          <input
                            type="text"
                            id="input-username"
                            class="form-control"
                            value={dailyTaskMinValue}
                            onChange={(e) => {
                              setDailyTaskMinValue(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  dailyTaskMinValue:
                                    "Daily Task Minimum Value is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  dailyTaskMinValue: "",
                                });
                              }
                            }}
                          />
                          {error.dailyTaskMinValue && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.dailyTaskMinValue}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label
                            class="form-control-label"
                            for="input-username"
                          >
                            Maximum Value
                          </label>
                          <input
                            type="text"
                            id="input-username"
                            class="form-control"
                            value={dailyTaskMaxValue}
                            onChange={(e) => {
                              setDailyTaskMaxValue(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  dailyTaskMaxValue:
                                    "Daily Task Maximum Value is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  dailyTaskMaxValue: "",
                                });
                              }
                            }}
                          />
                          {error.dailyTaskMaxValue && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.dailyTaskMaxValue}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <a
                        href="#!"
                        class="btn btn-danger float-right"
                        onClick={handleSubmit}
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mt--6">
        <div class="row">
          <div class="col-xl-6 order-xl-2">
            <div class="card card-profile">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Redeem Gateway </h3>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <form>
                  {/* <h6 class="heading-small text-muted mb-4">
                    User information
                  </h6> */}
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label
                            class="form-control-label mr-3"
                            for="input-username"
                          >
                            Redeem Gateway
                          </label>
                          {/* <FormControl className={classes.formControl}> */}
                          <Select
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            value={redeemGateway}
                            onChange={handleChange}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                              <div className={classes.chips}>
                                {selected.map((value) => (
                                  <Chip
                                    key={value}
                                    label={value}
                                    className={classes.chip}
                                  />
                                ))}
                              </div>
                            )}
                            MenuProps={MenuProps}
                          >
                            {gateway.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                // style={getStyles(name, redeemGateway)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* </FormControl> */}
                          {error.redeemGateway && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.redeemGateway}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label
                            class="form-control-label mr-3"
                            for="input-username"
                          >
                            Min. points to redeem
                          </label>
                          <input
                            type="number"
                            id="input-username"
                            class="form-control"
                            value={minPoints}
                            onChange={(e) => {
                              setMinPoints(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  minPoints: "Minimum point is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  minPoints: "",
                                });
                              }
                            }}
                          />
                          {error.minPoints && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.minPoints}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label
                            class="form-control-label mr-3"
                            for="input-username"
                          >
                            Choose your currency
                          </label>
                          <select
                            class="form-select form-control"
                            aria-label="Default select example"
                            value={currency}
                            onChange={(e) => {
                              setCurrency(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  currency: "Please select a Currency!",
                                });
                              } else if (e.target.value == "Currency") {
                                return setError({
                                  ...error,
                                  currency: "Please select a Currency!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  currency: "",
                                });
                              }
                            }}
                          >
                            <option selected>Currency</option>
                            <option value="₹">INR</option>
                            <option value="$">USD</option>
                            <option value="£">GBP</option>
                            <option value="€">EUR</option>
                          </select>
                          {error.currency && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.currency}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label
                            class="form-control-label mr-3"
                            for="input-username"
                          >
                            to&nbsp;
                            {currency}
                          </label>
                          <input
                            type="number"
                            disabled
                            id="input-username"
                            class="form-control"
                            value={toCurrency}
                            // onChange={(e) => {
                            //   setToCurrency(e.target.value);

                            //   if (!e.target.value) {
                            //     return setError({
                            //       ...error,
                            //       toCurrency: "To Currency is Required!",
                            //     });
                            //   } else {
                            //     return setError({
                            //       ...error,
                            //       toCurrency: "",
                            //     });
                            //   }
                            // }}
                          />
                          {/* {error.toCurrency && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.toCurrency}
                              </Typography>
                            </div>
                          )} */}
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label
                            class="form-control-label mr-3"
                            for="input-username"
                          >
                            How Many Coins
                          </label>
                          <input
                            type="number"
                            id="input-username"
                            class="form-control"
                            value={howManyCoins}
                            onChange={(e) => {
                              setHowManyCoins(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  howManyCoins: "How Many Coins is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  howManyCoins: "",
                                });
                              }
                            }}
                          />
                          {error.howManyCoins && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.howManyCoins}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="mr-3">Redeem Day</label>
                          <select
                            class="form-select form-control"
                            aria-label="Default select example"
                            value={redeemDay}
                            onChange={(e) => {
                              setRedeemDay(e.target.value);
                            }}
                          >
                            {day.map((day) => {
                              return (
                                <option class="text-dark" value={day.value}>
                                  {day.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <a
                        href="#!"
                        class="btn btn-success float-right"
                        onClick={handleSubmit}
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-xl-6 order-xl-2">
            <div class="card card-profile">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Payment Gateway </h3>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <form>
                  {/* <h6 class="heading-small text-muted mb-4">
                    User information
                  </h6> */}
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="row">
                            <div class="col-md-10">
                              <label
                                class="form-control-label"
                                for="input-username"
                              >
                                Stripe
                              </label>
                            </div>
                            <div class="col-md-2 pl-5">
                              <IOSSwitch
                                onChange={stripeSwitch}
                                checked={stripeSwitch_}
                                color="primary"
                              />
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-6">
                              <label class="form-control-label">
                                Stripe Secret key
                              </label>
                              <input
                                type="text"
                                id="input-username"
                                class="form-control"
                                value={stripeSecreteKey}
                                onChange={(e) => {
                                  setStripeSecreteKey(e.target.value);

                                  if (!e.target.value) {
                                    return setError({
                                      ...error,
                                      stripeSecreteKey:
                                        "Stripe Secret Key is Required!",
                                    });
                                  } else {
                                    return setError({
                                      ...error,
                                      stripeSecreteKey: "",
                                    });
                                  }
                                }}
                              />
                              {error.stripeSecreteKey && (
                                <div class="pl-1 text-left">
                                  <Typography variant="caption" color="error">
                                    {error.stripeSecreteKey}
                                  </Typography>
                                </div>
                              )}
                            </div>
                            <div class="col-md-6">
                              <label class="form-control-label">
                                Stripe Publishable key
                              </label>
                              <input
                                type="text"
                                id="input-username"
                                class="form-control"
                                value={stripePublishableKey}
                                onChange={(e) => {
                                  setStripePublishableKey(e.target.value);

                                  if (!e.target.value) {
                                    return setError({
                                      ...error,
                                      stripePublishableKey:
                                        "Stripe Publishable Key is Required!",
                                    });
                                  } else {
                                    return setError({
                                      ...error,
                                      stripePublishableKey: "",
                                    });
                                  }
                                }}
                              />
                              {error.stripePublishableKey && (
                                <div class="pl-1 text-left">
                                  <Typography variant="caption" color="error">
                                    {error.stripePublishableKey}
                                  </Typography>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="row">
                            <div class="col-md-10">
                              <label
                                class="form-control-label"
                                for="input-username"
                              >
                               phone pay  Id
                              </label>
                            </div>
                            <div class="col-md-2 pl-5">
                              <IOSSwitch
                                onChange={phoneSwtich}
                                checked={phonePaySwitch}
                                color="primary"
                              />
                            </div>
                          </div>

                          <input
                            type="text"
                            id="input-username"
                            class="form-control"
                            value={phonePayId}
                            onChange={(e) => {
                              setPhonePayId(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  phonePayId: "Phone Pay id is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  phonePayId: "",
                                });
                              }
                            }}
                          />
                          {error.phonePayId && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.phonePayId}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="row">
                            <div class="col-md-10">
                              <label
                                class="form-control-label"
                                for="input-username"
                              >
                               razor pay  Id
                              </label>
                            </div>
                            <div class="col-md-2 pl-5">
                              <IOSSwitch
                                onChange={razorSwitch}
                                checked={razorPaySwitch}
                                color="primary"
                              />
                            </div>
                          </div>

                          <input
                            type="text"
                            id="input-username"
                            class="form-control"
                            value={razorPayId}
                            onChange={(e) => {
                              setPhonePayId(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  razorPayId: "Razor Pay id is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  razorPayId: "",
                                });
                              }
                            }}
                          />
                          {error.razorPayId && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.razorPayId}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="row">
                            <div class="col-md-10">
                              <label
                                class="form-control-label"
                                for="input-username"
                              >
                                Google Pay Id
                              </label>
                            </div>
                            <div class="col-md-2 pl-5">
                              <IOSSwitch
                                onChange={googleSwitch_}
                                checked={googlePaySwitch}
                                color="primary"
                              />
                            </div>
                          </div>

                          <textarea
                            type="text"
                            id="input-username"
                            class="form-control"
                            rows="3"
                            cols="2"
                            value={googlePayId}
                            onChange={(e) => {
                              setGooglePayId(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  googlePayId: "Google Pay Id is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  googlePayId: "",
                                });
                              }
                            }}
                          />
                          {error.googlePayId && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.googlePayId}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <a
                        href="#!"
                        class="btn btn-warning float-right"
                        onClick={handleSubmit}
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          
          {/* <div class="col-xl-3 order-xl-2"></div> */}
        </div>
      </div>

      <div class="container-fluid mt--6">
        <div class="row">
          <div class="col-xl-6 order-xl-2">
            <div class="card card-profile">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0"> Call Bouns Setting</h3>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <form>
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="col-md-12">
                            <label
                              class="form-control-label"
                              for="input-username"
                            >
                              Call Bouns1
                            </label>
                          </div>
                          <input
                            type="number"
                            min="0"
                            id="input-username"
                            class="form-control"
                            value={callBonus1}
                            onChange={(e) => {
                              setCallBonus1(parseInt(e.target.value));
                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  callBouns1: "Call Bouns1 is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  callBouns1: "",
                                });
                              }
                            }}
                          />
                          {error.callBouns1 && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.callBouns1}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="col-md-12">
                            <label
                              class="form-control-label"
                              for="input-username"
                            >
                              Call Bouns2
                            </label>
                          </div>
                          <input
                            type="number"
                            min="0"
                            id="input-username"
                            class="form-control"
                            value={callBonus2}
                            onChange={(e) => {
                              setCallBonus2(parseInt(e.target.value));
                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  callBouns2: "Call Bouns1 is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  callBouns2: "",
                                });
                              }
                            }}
                          />
                          {error.callBonus2 && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.callBonus2}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="col-md-12">
                            <label
                              class="form-control-label"
                              for="input-username"
                            >
                              Call Bouns3
                            </label>
                          </div>
                          <input
                            type="number"
                            min="0"
                            id="input-username"
                            class="form-control"
                            value={callBonus3}
                            onChange={(e) => {
                              setCallBonus3(parseInt(e.target.value));
                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  callBouns3: "Call Bouns3 is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  callBouns3: "",
                                });
                              }
                            }}
                          />
                          {error.callBonus3 && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.callBonus3}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="col-md-12">
                            <label
                              class="form-control-label"
                              for="input-username"
                            >
                              Call Bouns4
                            </label>
                          </div>
                          <input
                            type="number"
                            min="0"
                            id="input-username"
                            class="form-control"
                            value={callBonus4}
                            onChange={(e) => {
                              setCallBonus4(parseInt(e.target.value));
                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  callBouns4: "Call Bouns4 is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  callBouns4: "",
                                });
                              }
                            }}
                          />
                          {error.callBonus4 && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.callBonus4}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="col-md-12">
                            <label
                              class="form-control-label"
                              for="input-username"
                            >
                              Call Bouns5
                            </label>
                          </div>
                          <input
                            type="number"
                            min="0"
                            id="input-username"
                            class="form-control"
                            value={callBonus5}
                            onChange={(e) => {
                              setCallBonus5(parseInt(e.target.value));
                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  callBouns5: "Call Bouns5 is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  callBouns5: "",
                                });
                              }
                            }}
                          />
                          {error.callBonus5 && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.callBonus5}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <a
                        href="#!"
                        class="btn btn-primary float-right"
                        onClick={handleSubmit}
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="col-xl-6 order-xl-2">
            <div class="card card-profile">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0"> Call Duration Setting</h3>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <form>
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="col-md-12">
                            <label
                              class="form-control-label"
                              for="input-username"
                            >
                              Call Duration1
                            </label>
                          </div>
                          <input
                            type="number"
                            min="0"
                            id="input-username"
                            class="form-control"
                            value={callDuration1}
                            onChange={(e) => {
                              setCallDuration1(parseInt(e.target.value));

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  callDuration1: "Call Duration1 is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  callDuration1: "",
                                });
                              }
                            }}
                          />
                          {error.callDuration1 && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.callDuration1}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="col-md-12">
                            <label
                              class="form-control-label"
                              for="input-username"
                            >
                              Call Duration2
                            </label>
                          </div>
                          <input
                            type="number"
                            min="0"
                            id="input-username"
                            class="form-control"
                            value={callDuration2}
                            onChange={(e) => {
                              setCallDuration2(parseInt(e.target.value));

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  callDuration2: "Call Duration Is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  callDuration2: "",
                                });
                              }
                            }}
                          />
                          {error.callDuration2 && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.callDuration2}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="col-md-12">
                            <label
                              class="form-control-label"
                              for="input-username"
                            >
                              Call Duration3
                            </label>
                          </div>
                          <input
                            type="number"
                            min="0"
                            id="input-username"
                            class="form-control"
                            value={callDuration3}
                            onChange={(e) => {
                              setCallDuration3(parseInt(e.target.value));

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  callDuration3: "Call Duration3 is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  callDuration3: "",
                                });
                              }
                            }}
                          />
                          {error.callDuration3 && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.callDuration3}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="col-md-12">
                            <label
                              class="form-control-label"
                              for="input-username"
                            >
                              Call Duration4
                            </label>
                          </div>
                          <input
                            type="number"
                            min="0"
                            id="input-username"
                            class="form-control"
                            value={callDuration4}
                            onChange={(e) => {
                              setCallDuration4(parseInt(e.target.value));

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  callDuration4: "Call Duration4 is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  callDuration4: "",
                                });
                              }
                            }}
                          />
                          {error.callDuration4 && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.callDuration4}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="col-md-12">
                            <label
                              class="form-control-label"
                              for="input-username"
                            >
                              Call Duration5
                            </label>
                          </div>
                          <input
                            type="number"
                            min="0"
                            id="input-username"
                            class="form-control"
                            value={callDuration5}
                            onChange={(e) => {
                              setCallDuration5(parseInt(e.target.value));

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  callDuration5: "Call Duration5 is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  callDuration5: "",
                                });
                              }
                            }}
                          />
                          {error.callDuration5 && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.callDuration5}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <a
                        href="#!"
                        class="btn btn-primary float-right"
                        onClick={handleSubmit}
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  getSetting,
  editSetting,
  handleGoogleSwitch,
  handleRazorSwitch,
  handleStripeSwitch,
  handlePhoneSwitch,
})(SettingPage);
