import * as ActionType from "./type";

const initialState = {
  hostImage: [],
  hostImageProfile: [],
  hostSingleImage: [],
};

export const hostImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_HOST_IMAGE:
      return {
        ...state,
        hostImage: action.payload,
      };
    case ActionType.GET_HOST_IMAGE_PROFILE:
      return {
        ...state,
        hostImageProfile: action.payload,
      };

    case ActionType.GET_SINGLE_HOST_IMAGE:
      return {
        ...state,
        hostSingleImage: action.payload,
      };

    case ActionType.SWITCH_HOST_IMAGE:
      if (action.payload.isProfile) {
        ;
        return {
          ...state,
          hostImageProfile: state.hostImageProfile.filter(
            (data) => data._id !== action.payload?.hostImage._id && data
          ),
        };
      } else {
        return {
          ...state,
          hostImage: state.hostImage.filter(
            (data) => data._id !== action.payload.hostImage._id && data
          ),
        };
      }

    case ActionType.SWITCH_HOST_PROFILE:
      return {
        ...state,
        hostImageProfile: state.hostImageProfile.filter(
          (data) => data._id !== action.payload && data
        ),
      };
    case ActionType.DELETE_SINGLE_HOST_IMAGE:
      return {
        ...state,
        hostSingleImage: state.hostSingleImage.filter(
          (image) => image._id !== action.payload.hostImage._id && image
        ),
      };
    default:
      return state;
  }
};
