import axios from "axios";

import {
  GET_PENDING_REQUEST,
  ACCEPT_PENDING_REQUEST,
  CLOSE_REQUEST_DIALOG,
  SET_ACCEPT_REQUEST_DONE,
  DELETE_HOST_REQUEST,
  EDIT_HOST_REQUEST,
} from "./types";

export const getPendingRequest = () => (dispatch) => {
  axios
    .get("/request")
    .then((res) => {
      dispatch({ type: GET_PENDING_REQUEST, payload: res.data.data });
    })
    .catch((error) => console.log(error));
};

export const acceptPendingRequest = (data, id) => (dispatch) => {
  axios
    .post(`/request/${id}`, data)
    .then((res) => {
      console.log("res", res);

      dispatch({ type: ACCEPT_PENDING_REQUEST, payload: id });
      dispatch({ type: CLOSE_REQUEST_DIALOG });
      dispatch({ type: SET_ACCEPT_REQUEST_DONE });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const deleteHostRequest = (data) => (dispatch) => {
  

  axios
    .patch(`/request/rejectRequest?request_id=${data}`)
    .then((res) => {
      
    
      if (res.data.status) {
        dispatch({ type: DELETE_HOST_REQUEST, payload: data });
      } else {
        console.log(res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const updateHostRequest = (formData, hostRequestId) => (dispatch) => {

  axios
    .patch(`/request?request_id=${hostRequestId} `, formData)
    .then((res) => {
     
      console.log(res.data.request);
      if (res.data.status) {
        dispatch({
          type: EDIT_HOST_REQUEST,
          payload: { data: res.data.request, id: hostRequestId },
        });
      } else {
        console.log(res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
