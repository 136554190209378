import React, { Fragment, useState } from "react";

//MUI
import { TablePagination } from "@material-ui/core";
import TablePaginationActions from "./TablePagination";

import { Link, useLocation } from "react-router-dom";

import ReactHTMLTableToExcel from "react-html-table-to-excel";

// //datepicker
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import dayjs from "dayjs";
import { connect, useSelector } from "react-redux";
import { useEffect } from "react";

import { getHostSettlementHistoryReport } from "../../store/host/action";

const HostSettlementReportHistory = (props) => {
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);

  var startDate = location?.state?.startDate?.split(",") || []
  var endDate = location?.state?.endDate?.split(",") || []



  useEffect(() => {
    props.getHostSettlementHistoryReport(location.state.agencyId, "custom" , startDate[0] ,endDate[0]);
  }, [location]);

  const { hostSettlementHistory: host } = useSelector((state) => state.host);

  useEffect(() => {
    setData(host);
  }, [host]);

  //Apply button function for analytic
  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("DD-MM-YYYY") +
        " - " +
        picker.endDate.format("DD-MM-YYYY")
    );
    const dayStart = dayjs(picker.startDate).format("M/D/YYYY");

    const dayEnd = dayjs(picker.endDate).format("M/D/YYYY");

    props.getHostSettlementHistoryReport(
      location.state.agencyId,
      "custom",
      dayStart,
      dayEnd
    );
  };

  //Cancel button function for analytic
  const handleCancel = (event, picker) => {
    picker.element.val("");
    props.getHostSettlementHistoryReport(location.state.agencyId, "week");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase();

    if (value) {
      const data = host.filter((data) => {
        return (
          data?.hostName?.toUpperCase()?.indexOf(value) > -1 ||
          data?.agencyName?.toUpperCase()?.indexOf(value) > -1 ||
          data?.country?.toUpperCase()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      return setData(host);
    }
  };

  return (
    <>
      <Fragment>
        <div class="page-breadcrumb">
          <div class="row">
            <div class="col-7 align-self-center">
              <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb m-0 p-0">
                    <li class="breadcrumb-item">
                      <Link to="/admin/dashboard" class="text-muted">
                        Home
                      </Link>
                    </li>
                    <li class="breadcrumb-item">
                      <Link to="/admin/host/allHost" class="text-muted">
                        Host
                      </Link>
                    </li>
                    <li
                      class="breadcrumb-item text-muted active"
                      aria-current="page"
                    >
                      Host Settlement History
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-5  ">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-primary float-right"
                table="host-table"
                filename="HostTable"
                sheet="hosts"
                buttonText="Export To Excel"
              />
            </div>
          </div>
        </div>

        <table
          id="host-table"
          class="table table-striped table-bordered no-wrap d-none"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Id No</th>
              <th>Report To</th>
              <th>Country</th>
              <th>First Approved Date</th>
              <th>Call Duration</th>
              <th>Answer Rate</th>
              <th>Video Chat Coins</th>
              <th>Gifts</th>
              <th>Bonus</th>
              <th>Total Coin</th>
              <th>Left Over In Last Week</th>
              <th>Effective Coin</th>
              <th>Anchor's Salary</th>
              <th>Agency Commission</th>
              <th>Agency's Salary</th>
              <th>Total</th>
              <th>From Date</th>
              <th>To Date</th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              <Fragment>
                {(rowsPerPage > 0
                  ? data.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data
                ).map((data, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ verticalAlign: "middle" }}>
                        {data.hostName}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {data.username}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {data.agencyName}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {data.country}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {dayjs(data.approvedDate).format("DD MMM, YYYY")}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {data.videoCallDuration + " sec"}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {data.answerRate.toFixed(2) + " %"}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {data.coinHistory}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {data.totalGiftIncome}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>{data.bonus}</td>
                      <td style={{ verticalAlign: "middle" }}>
                        {data.totalCoin}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {data.leftOverCoin}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {data.effectiveCoin?.toFixed(2)}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {data.anchorSalary.toFixed(2)}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {`${data.agencyCommission}%`}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {data?.agencySalary?.toFixed(2)}
                      </td>

                      <td style={{ verticalAlign: "middle" }}>
                        {data.totalAgencySalary.toFixed(2)}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {dayjs(data.startDate).format("DD/MM/YYYY")}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {dayjs(data.endDate).format("DD/MM/YYYY")}
                      </td>
                    </tr>
                  );
                })}
              </Fragment>
            ) : (
              <tr>
                <td colSpan="19" align="center">
                  Nothing to show!!
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th>Name</th>
              <th>Id No</th>
              <th>Report To</th>
              <th>Country</th>
              <th>First Approved Date</th>
              <th>Call Duration</th>
              <th>Answer Rate</th>
              <th>Video Chat Coins</th>
              <th>Gifts</th>
              <th>Bonus</th>
              <th>Total Coin</th>
              <th>Left Over In Last Week</th>
              <th>Effective Coin</th>
              <th>Anchor's Salary</th>
              <th>Agency Commission</th>
              <th>Agency's Salary</th>
              <th>Total</th>
              <th>From Date</th>
              <th>To Date</th>
            </tr>
          </tfoot>
        </table>

        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8 mt-4 float-left">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 mt-xs-3 mt-2">
                          <DateRangePicker
                            initialSettings={{
                              autoUpdateInput: false,
                              locale: {
                                cancelLabel: "Clear",
                              },
                              maxDate: new Date(),

                              buttonClasses: ["btn btn-dark"],
                            }}
                            onApply={handleApply}
                            onCancel={handleCancel}
                          >
                            <input
                              type="text"
                              class="daterange form-control float-left "
                              placeholder="Select Date"
                              style={{ width: 185, fontWeight: 700 }}
                            />
                          </DateRangePicker>
                        </div>
                      </div>
                      {/* <h3 class="card-title">Host</h3> */}
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-3 float-right mt-2">
                      <form action="">
                        <div class="input-group mb-4 border rounded-pill p-1">
                          <div class="input-group-prepend border-0">
                            <div
                              id="button-addon4"
                              class="btn btn-link text-primary"
                            >
                              <i class="fa fa-search"></i>
                            </div>
                          </div>
                          <input
                            type="search"
                            placeholder="What're you searching for?"
                            aria-describedby="button-addon4"
                            class="form-control bg-none border-0 rounded-pill mr-1"
                            onChange={handleSearch}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8 mt-3 float-left mb-0"></div>
                  </div>

                  <div class="table-responsive">
                    <table
                      id="zero_config"
                      class="table table-striped table-bordered no-wrap"
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                      
                          <th>Report To</th>
                          <th>Country</th>
                          <th>First Approved Date</th>
                          <th>Call Duration</th>
                          <th>Answer Rate</th>
                          <th>Video Chat Coins</th>
                          <th>Gifts</th>
                          <th>Bonus</th>
                          <th>Total Coin</th>
                          <th>Left Over In Last Week</th>
                          <th>Effective Coin</th>
                          <th>Anchor's Salary</th>
                          <th>Agency Commission</th>
                          <th>Agency's Salary</th>
                          <th>Total</th>
                          <th>From Date</th>
                          <th>To Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 ? (
                          <Fragment>
                            {(rowsPerPage > 0
                              ? data.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                              : data
                            ).map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {data.hostName}
                                  </td>
                               
                                  <td style={{ verticalAlign: "middle" }}>
                                    {data.agencyName}
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {data.country}
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {dayjs(data.approvedDate).format(
                                      "DD MMM, YYYY"
                                    )}
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {data.videoCallDuration + " sec"}
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {data.answerRate.toFixed(2) + " %"}
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {data.coinHistory}
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {data.totalGiftIncome}
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {data.bonus}
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {data.totalCoin}
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {data.leftOverCoin}
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {data.effectiveCoin?.toFixed(2)}
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {data.anchorSalary.toFixed(2)}
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {`${data?.agencyCommission}%`}
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {data.agencySalary.toFixed(2)}
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {data.totalAgencySalary.toFixed(2)}
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {dayjs(data.startDate).format("DD/MM/YYYY")}
                                  </td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {dayjs(data.endDate).format("DD/MM/YYYY")}
                                  </td>
                                </tr>
                              );
                            })}
                          </Fragment>
                        ) : (
                          <tr>
                            <td colSpan="19" align="center">
                              Nothing to show!!
                            </td>
                          </tr>
                        )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Name</th>
                        
                          <th>Report To</th>
                          <th>Country</th>
                          <th>First Approved Date</th>
                          <th>Call Duration</th>
                          <th>Answer Rate</th>
                          <th>Video Chat Coins</th>
                          <th>Gifts</th>
                          <th>Bonus</th>
                          <th>Total Coin</th>
                          <th>Left Over In Last Week</th>
                          <th>Effective Coin</th>
                          <th>Anchor's Salary</th>
                          <th>Agency Commission</th>
                          <th>Agency's Salary</th>
                          <th>Total</th>
                          <th>From Date</th>
                          <th>To Date</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div class="py-2">
                    <TablePagination
                      id="pagination"
                      component="div"
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default connect(null, { getHostSettlementHistoryReport })(
  HostSettlementReportHistory
);
