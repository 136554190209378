import {
  GET_OFFLINE_RECHARGE_HISTORY,
  GET_PURCHASE_COIN_HISTORY,
} from "./types";

const initialState = {
  history: [],
  offlineHistory: [],
};

const purchaseCoinHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PURCHASE_COIN_HISTORY:
      return {
        ...state,
        history: action.payload,
      };

    case GET_OFFLINE_RECHARGE_HISTORY:
      return {
        ...state,
        offlineHistory: action.payload,
      };

    default:
      return state;
  }
};

export default purchaseCoinHistoryReducer;
