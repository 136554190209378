export const GET_USER = "GET_USER";
export const BLOCK_UNBLOCK_USER = "BLOCK_UNBLOCK_USER";

//edit coin of user
export const EDIT_USER_COIN = "EDIT_USER_COIN";

export const EDIT_USER = "EDIT_USER";
export const SET_UPDATE_USER_DONE = "SET_UPDATE_USER_DONE";
export const UNSET_UPDATE_USER_DONE = "UNSET_UPDATE_USER_DONE";

export const OPEN_USER_DIALOG = "OPEN_USER_DIALOG";
export const CLOSE_USER_DIALOG = "CLOSE_USER_DIALOG";

export const GET_OFFLINE_TYPE = "GET_OFFLINE_TYPE";
export const CLOSE_OFFLINE_TYPE = "CLOSE_OFFLINE_TYPE";
