import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

//redux
import { connect } from "react-redux";
import { getProfile } from "../store/admin/action";
import { getUser } from "../store/user/action";
import { getCountry } from "../store/country/action";
import { getCategory } from "../store/category/action";
import { getGift } from "../store/gift/action";
import { getEmoji } from "../store/emoji/action";
import { getSticker } from "../store/sticker/action";
import { getPlan } from "../store/plan/action";
import { getSetting } from "../store/setting/action";
import { getPurchaseCoinHistory } from "../store/purchaseCoinHistory/action";
import { getReportedUser } from "../store/reportUser/action";

//components
import AgencyTable from "../Components/Table/AgencyTable";
import UserTable from "../Components/Table/UserTable";
import CountryTable from "../Components/Table/CountryTable";
import CategoryTable from "../Components/Table/CategoryTable";
import ImageTable from "../Components/Table/ImageTable";
import GiftTable from "../Components/Table/GiftTable";
import EmojiTable from "../Components/Table/EmojiTable";
import StickerTable from "../Components/Table/StickerTable";
import ReportUserTable from "../Components/Table/ReportUserTable";
import ViewReportUserTable from "../Components/Table/ViewReportUserTable";
import PlanTable from "../Components/Table/PlanTable";
import VIPPlanTable from "../Components/Table/VIPPlanTable";
import PurchaseCoinHistoryTable from "../Components/Table/PurchaseCoinHistoryTable";
import GoogleFbTable from "../Components/Table/GoogleFbTable";
import HostTable from "../Components/Table/HostTable";
import AgencyWiseHostTable from "../Components/Table/AgencyWiseHostTable";
import RedeemTable from "../Components/Table/RedeemTable";
import ProfilePage from "./Profile";
import SettingPage from "./Settings";
import DashboardPage from "./Dashboard";
import Navbar from "../Components/Navbar/Navbar";
import PendingRequestTable from "../Components/Table/PendingHostRequestTable";
import AcceptedRedeemTable from "../Components/Table/AcceptedRedeemTable";
import AgencyPendingRedeemTable from "../Components/Table/AgencyPendingRedemTable";
import AgencyAcceptedRedeemTable from "../Components/Table/AgencyAcceptedRedeemTable";
import LevelTable from "../Components/Table/LevelTable";
import HostAnalytic from "../Components/Table/HostAnalytic";
import AgencyAcceptedTable from "../Components/Table/AgencyAcceptedTable";
import AgencyPendingTable from "../Components/Table/AgencyPendingTable";
import HostLevelTable from "../Components/Table/HostLevelTable";
import AgencySolvedComplainTable from "../Components/Table/AgencySolvedComplainTable";
import AgencyPendingComplainTable from "../Components/Table/AgencyPendingComplainTable";
import PendingComplainTable from "../Components/Table/PendingComplainTable";
import SolvedComplainTable from "../Components/Table/SolvedComplainTable";
import UserPendingComplainTable from "../Components/Table/UserPendingComplainTable";
import UserSolvedComplainTable from "../Components/Table/UserSolvedComplainTable";
import BannerTable from "../Components/Table/BannerTable";
import OfflineRecharge from "./OfflineRecharge";

import Spinner from "../Components/Spinner";
import AllHostTable from "../Components/Table/AllHostTable";
import HostInfo from "./HostInfo";
import OfflineRechargeHistory from "../Components/Table/OfflineRechargeHistory";
import AgencyWiseSettlementReport from "../Components/Table/AgencyWiseSettlementReport";
import OnlineUserTable from "../Components/Table/OnlineUserTable";
import LiveOnlineHostTable from "../Components/Table/LiveOnlineHostTable";
import HostSettlementReportHistory from "../Components/Table/HostSettlementReportHistory";
import FakeHostTable from "../Components/Table/FakeHostTable";
import HostImageTable from "../Components/Table/HostImageTable";
import HostweekWiseSettlement from "../Components/Table/HostweekWiseSettlement";

const Admin = (props) => {
  const location = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === "/admin") {
      history.push("/admin/dashboard");
    }
  }, []);

  // (() => {
  //   if (window.localStorage) {
  //     if (history.location.pathname === "/admin") {
  //       history.push("/admin/dashboard");
  //     }

  //     if (!localStorage.getItem("firstLoad")) {
  //       localStorage["firstLoad"] = true;
  //       window.location.reload(true);
  //     }
  //   }
  // })();

  // useEffect(() => {
  //   props.getUser();
  //   props.getCountry();
  //   props.getCategory();
  //   props.getGift();
  //   props.getEmoji();
  //   props.getSticker();
  //   props.getPlan();
  //   props.getSetting();
  //   props.getPurchaseCoinHistory();
  //   props.getReportedUser();
  //   props.getProfile();
  // }, []);

  return (
    <Fragment>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        <Navbar />
        {/* </div> */}
        <div class="page-wrapper" style={{ display: "block" }}>
          <Switch>
            <Route
              path={`${location.path}/dashboard`}
              component={DashboardPage}
            />
            <Route
              exact
              path={`${location.path}/banner`}
              component={BannerTable}
            />
            <Route
              exact
              path={`${location.path}/recharge`}
              component={OfflineRecharge}
            />
            <Route
              exact
              path={`${location.path}/offlineRechargeHistory`}
              component={OfflineRechargeHistory}
            />
            <Route
              exact
              path={`${location.path}/country`}
              component={CountryTable}
            />
            <Route
              exact
              path={`${location.path}/category`}
              component={CategoryTable}
            />
            <Route exact path={`${location.path}/user`} component={UserTable} />
            <Route
              exact
              path={`${location.path}/image`}
              component={ImageTable}
            />
            <Route
              path={`${location.path}/emoji`}
              exact
              component={EmojiTable}
            />
            <Route
              exact
              path={`${location.path}/host/agency`}
              component={AgencyWiseHostTable}
            />

            <Route
              exact
              path={`${location.path}/fakeHost`}
              component={FakeHostTable}
            />

            <Route exact path={`${location.path}/host`} component={HostTable} />
            <Route
              exact
              path={`${location.path}/agency/agencySettlement`}
              component={AgencyWiseSettlementReport}
            />
            <Route
              exact
              path={`${location.path}/allHost`}
              component={AllHostTable}
            />
            <Route
              exact
              path={`${location.path}/allHost/hostInfo`}
              component={HostInfo}
            />
            <Route
              exact
              path={`${location.path}/host/analytic/:id`}
              component={HostAnalytic}
            />
            <Route
              exact
              path={`${location.path}/report/:id`}
              component={ViewReportUserTable}
            />
            <Route
              exact
              path={`${location.path}/sticker`}
              component={StickerTable}
            />
            <Route exact path={`${location.path}/gift`} component={GiftTable} />
            <Route
              exact
              path={`${location.path}/googleFb`}
              component={GoogleFbTable}
            />
            <Route
              exact
              path={`${location.path}/history`}
              component={PurchaseCoinHistoryTable}
            />
            <Route exact path={`${location.path}/plan`} component={PlanTable} />
            <Route
              exact
              path={`${location.path}/vipplan`}
              component={VIPPlanTable}
            />
            <Route
              exact
              path={`${location.path}/report`}
              component={ReportUserTable}
            />

            <Route
              exact
              path={`${location.path}/redeem`}
              component={AgencyPendingRedeemTable}
            />
            <Route
              exact
              path={`${location.path}/redeem/agency/:id`}
              component={RedeemTable}
            />
            <Route
              exact
              path={`${location.path}/redeem_/accepted`}
              component={AgencyAcceptedRedeemTable}
            />
            <Route
              exact
              path={`${location.path}/redeem_/accepted/agency/:id`}
              component={AcceptedRedeemTable}
            />


            <Route
              exact
              path={`${location.path}/setting`}
              component={SettingPage}
            />
            <Route
              exact
              path={`${location.path}/profile`}
              component={ProfilePage}
            />
            <Route
              exact
              path={`${location.path}/agency`}
              component={AgencyTable}
            />
            <Route
              exact
              path={`${location.path}/request`}
              component={PendingRequestTable}
            />
            <Route
              exact
              path={`${location.path}/level`}
              component={LevelTable}
            />
            <Route
              exact
              path={`${location.path}/host/level`}
              component={HostLevelTable}
            />
            <Route
              exact
              path={`${location.path}/agency_/accepted`}
              component={AgencyAcceptedTable}
            />
            <Route
              exact
              path={`${location.path}/agency_/pending`}
              component={AgencyPendingTable}
            />
            <Route
              exact
              path={`${location.path}/agency_/pendingComplain`}
              component={AgencyPendingComplainTable}
            />
            <Route
              exact
              path={`${location.path}/agency_/solvedComplain`}
              component={AgencySolvedComplainTable}
            />
            <Route
              exact
              path={`${location.path}/user_/solvedComplain`}
              component={UserSolvedComplainTable}
            />
            <Route
              exact
              path={`${location.path}/user_/pendingComplain`}
              component={UserPendingComplainTable}
            />
            <Route
              exact
              path={`${location.path}/agency_/solvedComplain/:id`}
              component={SolvedComplainTable}
            />
            <Route
              exact
              path={`${location.path}/agency_/pendingComplain/:id`}
              component={PendingComplainTable}
            />
            <Route
              exact
              path={`${location.path}/user/onlineUser`}
              component={OnlineUserTable}
            />

            <Route
              exact
              path={`${location.path}/hostImage`}
              component={HostImageTable}
            />
            <Route
              exact
              path={`${location.path}/host/liveOnlineHost`}
              component={LiveOnlineHostTable}
            />

            <Route
              exact
              path={`${location.path}/host/hostSettlementReportHistory`}
              component={HostSettlementReportHistory}
            />
              <Route
              exact
              path={`${location.path}/host/hostWeekWiseSettlement`}
              component={HostweekWiseSettlement}
            />
          </Switch>
          <Spinner />
        </div>
      </div>
    </Fragment>
  );
};

export default connect(null, {
  getUser,
  getCountry,
  getCategory,
  getGift,
  getEmoji,
  getSticker,
  getPlan,
  getSetting,
  getPurchaseCoinHistory,
  getReportedUser,
  getProfile,
})(Admin);

export const state = "https://dev.digicean.com/api/clientpackagechk";
