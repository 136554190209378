import React, { useState, useEffect } from "react";

//router
import { Link } from "react-router-dom";



//redux
import { connect, useDispatch, useSelector } from "react-redux";
import { editCoin } from "../store/user/action";

//MUI
import { Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { CLOSE_OFFLINE_TYPE } from "../store/user/types";

const SettingPage = (props) => {
  const dispatch = useDispatch();

  // const [mongoId, setMongoId] = useState(null);
  const [coin, setCoin] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [username, setUsername] = useState("");
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");

  const [error, setError] = useState({
    username: "",
    coin: "",
  });

  const { offlineCoinResponseType, offlineCoinResponseMessage, setToast } =
    useSelector((state) => state.user);        

 

  // useEffect(() => {
  //   if (updateDone) {
  //     setCoin("");
  //     setUsername("");
  //     setOpenSuccess(true);
  //     dispatch({ type: UNSET_UPDATE_USER_DONE });
  //   }
  // }, [updateDone, dispatch]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   

  //   if (!userId || !coin) {
  //     const errors = {};
  //     if (!userId) errors.userId = "User Id is Required!";
  //     if (!coin) errors.coin = "Coin is Required!";

  //     return setError({ ...errors });
  //   }
  //   const index = user.find((user) => user.uniqueId === userId);

  //   console.log("index", index);

  //   if (index !== undefined) {
  //     props.editCoin(index._id, { coin });
  //     // setOpenSuccess(true);
  //     // if (index.uniqueId === userId) {
  //     // } else {
  //     //   return setError({ ...error, userId: "User Id does not exist!" });
  //     // }
  //   } else {
  //     return setError({ ...error, userId: "User Id does not exist!" });
  //   }
  // };

  useEffect(() => {
    if (setToast) {
      setOpenSuccess(true);
      setType(offlineCoinResponseType);
      setMessage(offlineCoinResponseMessage);
      setUsername("");
      setCoin("");
      dispatch({ type: CLOSE_OFFLINE_TYPE });
    }
  }, [setToast]);

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  //Handle Submit
  const handleSubmit = () => {
    if (!username || !coin) {
      const error = {};

      if (!username) error.username = "Username is required !";
      if (!coin) error.coin = "Coin is required !";

      setError({ ...error });
    } else {
      const data = {
        username: username,
        coin: coin,
      };

      props.editCoin(data);
    }
  };

  return (
    <div class="main-content mt-5" id="panel">
      <Snackbar
        open={openSuccess}
        autoHideDuration={2000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity={type ? "success" : "error"}
        >
          {message}
        </Alert>
      </Snackbar>
      {/* <!-- Page content --> */}
      <div class="page-breadcrumb pt-1 mb-4">
        <div class="row">
          <div class="col-7 align-self-center">
            <div class="d-flex align-items-center">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0 p-0">
                  <li class="breadcrumb-item">
                    <Link to="/admin/dashboard" class="text-muted">
                      Home
                    </Link>
                  </li>
                  <li
                    class="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Offline Recharge
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt--6">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-xl-8">
            <div class="card card-profile">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Offline Recharge to User </h3>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <form autoComplete="off">
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label
                            class="form-control-label"
                            for="input-username"
                          >
                            User Name
                          </label>
                          <input
                            type="text"
                            id="input-username"
                            class="form-control"
                            value={username}
                            onChange={(e) => {
                              setUsername(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  username: "Username Id is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  username: "",
                                });
                              }
                            }}
                          />
                          {error.username && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.username}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label
                            class="form-control-label"
                            for="input-username"
                          >
                            Coin
                          </label>
                          <input
                            type="number"
                            id="input-username"
                            class="form-control"
                            value={coin}
                            onChange={(e) => {
                              setCoin(parseInt(e.target.value));

                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  coin: "Coin is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  coin: "",
                                });
                              }
                            }}
                          />
                          {error.coin && (
                            <div class="pl-1 text-left">
                              <Typography variant="caption" color="error">
                                {error.coin}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <button
                        type="button"
                        class="btn btn-default float-right"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  editCoin,
})(SettingPage);
