import axios from "axios";
import * as ActionType from "./type";

export const getHostImage = () => (dispatch) => {
  axios
    .get("/imageAccept/getHostImage")
    .then((res) => {
      console.log("res.data.data", res.data.data);
      dispatch({
        type: ActionType.GET_HOST_IMAGE,
        payload: res.data.data,
      });
    })
    .catch((error) => console.log("error", error));
};

export const getHostProfile = () => (dispatch) => {
  axios
    .get("/imageAccept/getImagePrifile")
    .then((res) => {
      console.log("isProfile", res.data);
      dispatch({
        type: ActionType.GET_HOST_IMAGE_PROFILE,
        payload: res.data.data,
      });
    })
    .catch((error) => console.log("error", error));
};

// get host single image

export const hostSingleImage = (id) => (dispatch) => {
  axios
    .get(`/imageAccept/getImageByHost?hostId=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.GET_SINGLE_HOST_IMAGE,
          payload: res.data.data,
        });
      }
    })
    .catch((error) => console.log(error));
};

export const isApproveSwitchProfile = (id) => (dispatch) => {
  axios
    .post(`/imageAccept/approveProfilePic?imageId=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.SWITCH_HOST_PROFILE,
          payload: id,
        });
      }
    })
    .catch((error) => console.log(error));
};

export const isApproveSwitch = (hostImage, type) => (dispatch) => {
  
  axios
    .post(
      `/imageAccept/handleSwitchisAccept?imageId=${hostImage._id}&type=${type}`
    )

    .then((res) => {
      
      if (res.data.status) {
        dispatch({
          type:
            type === "SingleImage"
              ? ActionType.DELETE_SINGLE_HOST_IMAGE
              : ActionType.SWITCH_HOST_IMAGE,
          payload: { hostImage, type },
        });
      }
    })
    .catch((error) => console.log(error));
};

export const deleteHostImage = (hostImage, type) => (dispatch) => {
  axios
    .delete(`/imageAccept/deleteHostImage?imageId=${hostImage._id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type:
            type === "SingleImage"
              ? ActionType.DELETE_SINGLE_HOST_IMAGE
              : ActionType.SWITCH_HOST_IMAGE,
          payload: { hostImage, type },
        });
      }
    })
    .catch((error) => console.log(error));
};

export const deleteAllImage = (hostImage, type) => (dispatch) => {
  axios
    .delete(`/imageAccept/deleteAllImage?imageId=${hostImage._id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.SWITCH_HOST_IMAGE,
          payload: { hostImage, type },
        });
      }
    })
    .catch((error) => console.log(error));
};
