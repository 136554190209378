import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import {
  anchorReport,
  getHostFaceRecognize,
  getHostWorkScreenshot,
} from "../store/host/action";
import { baseURL } from "../util/serverPath";

const HostInfo = (props) => {
  const location = useLocation();

  const host = JSON.parse(localStorage.getItem("Host_Info"));

  //   const [host, setHost] = useState({});
  const [hostVideo, setHostVideo] = useState("");
  const [hostWorkScreenshot, setHostWorkScreenshot] = useState([]);
  const [show, setShow] = useState(false);

  // const [data, setData] = useState({});
  const [type, setType] = useState("today");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    props.getHostFaceRecognize(location?.state?.host?._id);
    props.getHostWorkScreenshot(location?.state?.host?._id);

    // setHost(location?.state?.host);
  }, []);

  useEffect(() => {
    props.anchorReport(location?.state?.host?._id, type);
  }, [type]);

  const { hostFaceRecognize, anchorReport } = useSelector(
    (state) => state.host
  );
  const { screenshot } = useSelector((state) => state.host.screenshot);

  // useEffect(() => {
  //   setData(anchorReport);
  // }, [anchorReport]);

  useEffect(() => {
    setHostVideo(hostFaceRecognize?.video);
  }, [hostFaceRecognize]);

  useEffect(() => {
    setHostWorkScreenshot(screenshot);
  }, [screenshot]);

  //   Open Video in new tab
  const openInNewTab = (url) => {
    window.open(url, "_blank").focus();
  };

  const image = host.image.lastIndexOf();


  console.log("data in anchor report", anchorReport);

  return (
    <>
      <div class="page-breadcrumb">
        <div class="row">
          <div class="col-7 align-self-center">
            <div class="d-flex align-items-center">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0 p-0">
                  <li class="breadcrumb-item">
                    <Link to="/admin/dashboard" class="text-muted">
                      Home
                    </Link>
                  </li>
                  <li class="breadcrumb-item">
                    <Link to="/admin/host" class="text-muted">
                      Host
                    </Link>
                  </li>

                  <li
                    class="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Host Info
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-7 col-md-7 col-sm-12">
            <div class="card">
              <div class="card-header">
                <h3>Profile Detail</h3>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table id="zero_config" class="">
                    <tr>
                      <td>
                        <label class="font-weight-bold">Agency Name</label>
                      </td>
                      <td>
                        <label class="font-weight-bold">&nbsp;:</label>
                      </td>
                      <td>
                        <label>&nbsp;{host.agencyId?.name}</label>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <label class="font-weight-bold">Id</label>
                      </td>
                      <td>
                        <label class="font-weight-bold">&nbsp;:</label>
                      </td>
                      <td>
                        <label>&nbsp;{host.username}</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label class="font-weight-bold">Password</label>
                      </td>
                      <td>
                        <label class="font-weight-bold">&nbsp;:</label>
                      </td>
                      <td>
                        <label>
                          &nbsp;{show ? host.password : "**********"}
                          &nbsp;&nbsp;
                          {show ? (
                            <i
                              class="fa fa-eye pr-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => setShow(false)}
                            ></i>
                          ) : (
                            <i
                              class="fas fa-eye-slash pr-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => setShow(true)}
                            ></i>
                          )}
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label class="font-weight-bold">Country</label>
                      </td>
                      <td>
                        <label class="font-weight-bold">&nbsp;:</label>
                      </td>
                      <td>
                        <label>&nbsp;{host.country}</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label class="font-weight-bold">Coin</label>
                      </td>
                      <td>
                        <label class="font-weight-bold">&nbsp;:</label>
                      </td>
                      <td>
                        <label>&nbsp; &nbsp;
                          {host?.videoCallIncome +
                            host?.totalGiftIncome +
                            host?.callBonus}</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label class="font-weight-bold">Followers</label>
                      </td>
                      <td>
                        <label class="font-weight-bold">&nbsp;:</label>
                      </td>
                      <td>
                        <label>&nbsp;{host.followers_count}</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label class="font-weight-bold">Following</label>
                      </td>
                      <td>
                        <label class="font-weight-bold">&nbsp;:</label>
                      </td>
                      <td>
                        <label>&nbsp;{host.following_count}</label>
                      </td>
                    </tr>
                    {/* <tr>
                      <td>
                        <label class="font-weight-bold">
                          Earn Coin From Call
                        </label>
                      </td>
                      <td>
                        <label class="font-weight-bold">&nbsp;:</label>
                      </td>
                      <td>
                        <label>&nbsp;{totalCoin.totalCallCoin}</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label class="font-weight-bold">
                          Earn Coin From Gift
                        </label>
                      </td>
                      <td>
                        <label class="font-weight-bold">&nbsp;:</label>
                      </td>
                      <td>
                        <label>
                          &nbsp;
                          {totalCoin.totalGiftCoin}
                        </label>
                      </td>
                    </tr> */}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-7 col-sm-12">
            <div class="card">
              <div class="card-header">
                <h3>Profile Image</h3>
              </div>
              <div class="card-body text-center mt-2">
                <img
                  src={host.image[0]}
                  alt="img"
                  height="150px"
                  width="150px"
                  style={{ borderRadius: 5 }}
                />
                <div className="mt-3">
                  <strong>{host.name}</strong>
                </div>
                <div className="mt-1">
                  <label class="font-weight-bold text-left">Bio : </label>
                  <label>
                    &nbsp;
                    {host.bio}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-7 col-md-7 col-sm-12">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3>Host Work Screenshot</h3>
                </div>
                <div
                  className="card-body"
                  style={{
                    maxHeight: "265px",
                    overflow: "auto",
                  }}
                >
                  {hostWorkScreenshot?.length > 0 ? (
                    <div className="d-flex flex-wrap">
                      {hostWorkScreenshot?.map((screenshotData) => {
                        return (
                          <>
                            <div className="p-1 ">
                              <img
                                src={screenshotData?.screenshot}
                                alt=""
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  objectFit: "cover",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  openInNewTab(screenshotData?.screenshot)
                                }
                              />
                              <div className="d-flex justify-content-center">
                                <h3>{screenshotData?.dateString}</h3>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      No Data Found !
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-7 col-sm-12">
            <div class="card">
              <div class="card-header">
                <h3>Host Face Recognize</h3>
              </div>
              <div class="card-body text-center mt-2">
                <video
                  src={baseURL + "/" + hostVideo}
                  alt="video"
                  height="250px"
                  width="250px"
                  controls={true}
                  style={{ borderRadius: 5, cursor: "pointer" }}
                  onClick={() => openInNewTab(baseURL + "/" + hostVideo)}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row mt-3">
          <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 mt-3 float-right"></div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 mt-3 float-right">
            <ReactHtmlTableToExcel
              id="test-table-xls-button"
              className="btn btn-primary float-right"
              table="host-table"
              filename="AnchorReport"
              sheet="anchor-report"
              buttonText="Export To Excel"
            />
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-body">
            <div className="row">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4 float-left ">
                <h3 className="card-title">Anchor Report</h3>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4 mb-3 float-right">
                <select
                  class="form-select form-control"
                  aria-label="Default select example"
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                >
                  <option selected value="today">
                    Today
                  </option>
                  <option value="yesterday">Yesterday</option>
                  <option value="week">This Week</option>
                  <option value="lastWeek">Last Week</option>
                  <option value="month">This Month</option>
                  <option value="lastMonth">Last Month</option>
                </select>
              </div>
            </div>

            <div>
              <table
                id="host-table"
                class="table table-striped table-bordered no-wrap"
              >
                <thead>
                  <tr>
                    <th>Added Time</th>
                    <th>Online Duration</th>
                    <th>Averege Time </th>
                    <th>Live Duration </th>
                    <th>Unqulified Rate </th>
                    <th>Answer Rate </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{anchorReport.addedTime}</td>
                    <td>{anchorReport.onlineDuration} Minute</td>
                    <td style={{ verticalAlign: "middle" }}>
                      {anchorReport.averageTime} Minute
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      {anchorReport.liveDurations} Minute
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      {anchorReport.unqulifiedRate} %
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      {anchorReport.answerRate} %
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>Added Time</th>
                    <th>Online Duration</th>
                    <th>Averege Time </th>
                    <th>Live Duration </th>
                    <th>Unqulified Rate </th>
                    <th>Answer Rate </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default connect(null, {
  anchorReport,
  getHostFaceRecognize,
  getHostWorkScreenshot,
})(HostInfo);
