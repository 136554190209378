import {
  GET_PENDING_REQUEST,
  ACCEPT_PENDING_REQUEST,
  OPEN_REQUEST_DIALOG,
  CLOSE_REQUEST_DIALOG,
  SET_ACCEPT_REQUEST_DONE,
  UNSET_ACCEPT_REQUEST_DONE,
  DELETE_HOST_REQUEST,
  EDIT_HOST_REQUEST,
  OPEN_IMAGE_DIALOG,
  CLOSE_IMAGE_DIALOG,
} from "./types";

const initialState = {
  request: [],
  dialog: false,
  dialogData: null,
  createDone: false,
  imageDialogue: false,
  imageDialogue: null,
};

const requestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PENDING_REQUEST:
      return {
        ...state,
        request: action.payload,
      };

    case ACCEPT_PENDING_REQUEST:
      return {
        ...state,
        request: state.request.filter((data) => data._id !== action.payload),
      };

    case OPEN_REQUEST_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };

    case CLOSE_REQUEST_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    case SET_ACCEPT_REQUEST_DONE:
      return {
        ...state,
        createDone: true,
      };

    case UNSET_ACCEPT_REQUEST_DONE:
      return {
        ...state,
        createDone: false,
      };
    case DELETE_HOST_REQUEST:
      return {
        ...state,
        request: state.request.filter(
          (data) => data._id !== action.payload && data
        ),
      };
    case EDIT_HOST_REQUEST:
      return {
        ...state,
        request: state.request.map((requestData) =>
          requestData._id === action.payload.id
            ? action.payload.data
            : requestData
        ),
      };
    case OPEN_IMAGE_DIALOG:
      return {
        ...state,
        imageDialogue: true,
        imageDialogueData: action.payload || null,
      };
    case CLOSE_IMAGE_DIALOG:
      
      return {
        ...state,
        imageDialogue: false,
        imageDialogueData: null,
      };
    default:
      return state;
  }
};

export default requestReducer;
