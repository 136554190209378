export const GET_PENDING_REQUEST = "GET_PENDING_REQUEST";

export const ACCEPT_PENDING_REQUEST = "ACCEPT_PENDING_REQUEST";

export const OPEN_REQUEST_DIALOG = "OPEN_REQUEST_DIALOG";
export const CLOSE_REQUEST_DIALOG = "CLOSE_REQUEST_DIALOG";

export const SET_ACCEPT_REQUEST_DONE = "SET_ACCEPT_REQUEST_DONE";

export const UNSET_ACCEPT_REQUEST_DONE = "UNSET_ACCEPT_REQUEST_DONE";

export const DELETE_HOST_REQUEST = "DELETE_HOST_REQUEST";

export const EDIT_HOST_REQUEST = "EDIT_HOST_REQUEST";



export const OPEN_IMAGE_DIALOG = "OPEN_IMAGE_DIALOG";

export const CLOSE_IMAGE_DIALOG = "CLOSE_IMAGE_DIALOG";