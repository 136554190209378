import { BLOCK_UNBLOCK_HOST, GET_REPORTED_USER } from "./types";

const initialState = {
  report: [],
};

const reportUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTED_USER:
      return {
        ...state,
        report: action.payload,
      };
    // case GET_REPORT_USER:
    //   return {
    //     ...state,
    //     report: action.payload,
    //   };

    case BLOCK_UNBLOCK_HOST:
      return {
        ...state,
        report: state.report.map((report) => {
          if (report._id === action.payload._id)
            return {
              ...report,
              block: action.payload.block,
            };
          else return report;
        }),
      };

    default:
      return state;
  }
};

export default reportUserReducer;
