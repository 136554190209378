export const GET_HOST_IMAGE ="GET_HOST_IMAGE"

export const GET_HOST_IMAGE_PROFILE ="GET_HOST_IMAGE_PROFILE"

export const SWITCH_HOST_PROFILE ="SWITCH_HOST_PROFILE"

export const SWITCH_HOST_IMAGE ="SWITCH_HOST_IMAGE"

export const GET_SINGLE_HOST_IMAGE ="GET_SINGLE_HOST_IMAGE"

export const DELETE_SINGLE_HOST_IMAGE ="DELETE_SINGLE_HOST_IMAGE"


