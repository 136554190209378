import React, { Fragment, useState, useEffect } from "react";



//redux
import { useSelector, useDispatch, connect } from "react-redux";
import { CLOSE_HOST_DIALOG } from "../../store/host/types";
import {
  createNewHost,
  editFakeHost,
  createMultipleHost,
  editHost
} from "../../store/host/action";
import { getAgency } from "../../store/agency/action";

//server path
import { baseURL } from "../../util/serverPath";

//custom css
import "../../dist/css/style.min.css";
import "../../dist/css/style.css";

//custom javascript
import "../../dist/js/custom.min.js";
import "../../dist/js/app-style-switcher";
import "../../dist/js/sidebarmenu";
import "../../dist/js/feather.min.js";
import "../../assets/libs/perfect-scrollbar/dist/perfect-scrollbar.jquery.min.js";

//icon
import Cancel from "@material-ui/icons/Cancel";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";

//dialog
import Dialog from "@material-ui/core/Dialog";

const HostDialog = (props) => {
  const dispatch = useDispatch();
 

  const {
    dialog: open,
    dialogData,
    fakeHost,
  } = useSelector((state) => state.host);



  const AgencyList = useSelector((state) => state.agency.agency);

  const [imageData, setImageData] = useState([]);
  const [imagePath, setImagePath] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [videoPath, setVideoPath] = useState([]);
  const [mongoId, setMongoId] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [username, setUserName] = useState("");
  const [bio, setBio] = useState("");
  const [agency, setAgency] = useState("");
  const [password, setPassword] = useState({
    value: "",
    show: false,
  });
  const [hostId, setHostId] = useState("");
  const [type, setType] = useState("");

  const [errors, setError] = useState({
    hostId: "",
    password: "",
    name: "",
    image: "",
    username: "",
    bio: "",
    agency: "",
  });

  useEffect(() => {
    props.getAgency();
  }, []);

  useEffect(() => {
    if (dialogData) {
      setMongoId(dialogData?._id);
      setHostId(dialogData?.username);
      setPassword({ value: dialogData?.password });
      setName(dialogData?.name);
      setImagePath(dialogData?.image);
      setVideoPath(dialogData?.video);
      setUserName(dialogData.username);
      setBio(dialogData.bio);
      setAgency(dialogData?.agencyId._id);
      setCountry(dialogData?.country);
    }
  }, [dialogData]);

  const removeImage = () => {
    setImageData(null);
    setImagePath(null);
    setVideoData(null);
    setVideoPath(null);
  };

  useEffect(
    () => () => {
      setError({
        hostId: "",
        password: "",
        name: "",
        image: "",
        video: "",
        username: "",
        bio: "",
        agency: "",
      });
      setMongoId("");
      setHostId("");
      setPassword("");
      setName("");

      setUserName("");
      setBio("");
      setAgency("");
      setCountry("");
      setImageData(null);
      setImagePath(null);
      setVideoData(null);
      setVideoPath(null);
    },
    [open]
  );

  const handleInputImage = (e) => {
    setImageData(e.target.files);
    const files = e.target.files;
    console.log("files in image data", files);
    var data_ = [];
    for (let i = 0; i < files.length; i++) {
      data_.push(URL.createObjectURL(files[i]));
    }

    setImagePath(data_);
  };
  var fileSize = 0;
  const handleInputVideo = (e) => {
    setVideoData(e.target.files);
    const files = e.target.files;
    var data_ = [];
    for (let i = 0; i < files.length; i++) {
      data_.push(URL.createObjectURL(files[i]));
      fileSize += files[i].size;
      console.log(`video${1}------`, files[i].size);
    }
    console.log("video------", fileSize);
    if (fileSize >= 100000000) {
      errors.video = "Your File Limit Over 100MB";
    } else {
      errors.video = "";
    }
    setVideoPath(data_);
  };

  const handleShowPassword = () => {
    setPassword({ ...password, show: !password.show });
  };



  const closePopup = () => {
    dispatch({ type: CLOSE_HOST_DIALOG });
    setType("");
  };
  const createPassword = () => {
    const randomChars = "0123456789";
    let pass = "";
    for (let i = 0; i < 10; i++) {
      pass += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
      setPassword({ value: pass });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !password || !hostId) {
      const errors = {};
      if (!name) {
        errors.name = "Name can't be a blank!";
      }
      if (!username) {
        errors.username = "User Name can't be a blank!";
      }
      if (!password.value) {
        errors.password = "Password can't be a blank!";
      }
      if (!hostId) {
        errors.hostId = "Host Id can't be a blank!";
      }
      if (!country) {
        errors.hostId = "Country can't be a blank!";
      }

      // if (!imagePath) return setError({ ...errors });
    }
    if (agency == "") {
      return setError({
        ...errors,
        agency: "Please select an Agency!",
      });
    }

    if (errors.video !== "") {
      return setError({ ...errors, video: "Your File Limit Over 100MB" });
    }

    if (!mongoId) {
      const index = fakeHost.findIndex((host) => host.hostId === hostId);
      if (index > -1) {
        return setError({ ...errors, hostId: "Host Id already exist." });
      }
      if (!imageData) {
        return setError({ ...errors, imagePath: "Please select an Image!" });
      }
      if (!videoData) {
        return setError({ ...errors, videoPath: "Please select an Video!" });
      }
    } else {
      const index = fakeHost.find((host) => host.hostId === hostId);
      if (index !== undefined) {
        if (index._id === mongoId) {
        } else {
          return setError({ ...errors, hostId: "Host Id already exist." });
        }
      }
      if (imageData?.length === 0) {
        return setError({ ...errors, imagePath: "Please select an Image!" });
      }
      if (videoData?.length === 0) {
        return setError({ ...errors, videoPath: "Please select an Video!" });
      }
    }

    

    const formData = new FormData();
    // formData.append("hostId", hostId);
    formData.append("password", password.value);
    formData.append("name", name);
    formData.append("country", country);

    if (imageData) {
      if (imageData.length) {
        for (let i = 0; i < imageData.length; i++) {
          formData.append("image", imageData[i]);
        }
      } else {
        formData.append("image", imageData);
      }
    }

    if (videoData) {
      if (videoData.length) {
        for (let i = 0; i < videoData.length; i++) {
          formData.append("video", videoData[i]);
        }
      } else {
        formData.append("video", videoData);
      }
    }
    formData.append("username", username);
    formData.append("bio", bio);
    formData.append("agencyId", agency);

    if (dialogData) {
      if(dialogData?.isFake){

        props.editFakeHost(formData, mongoId);
      }else{
        props.editHost(formData, mongoId);

      }
    } else {
      if (type === "multiple") {
        props.createMultipleHost(formData);
      } else if (type === "single") {
        props.createNewHost(formData);
      }
    }
  };

  const videoPath_ = Array.isArray(videoPath);

  return (
    <Fragment>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="responsive-dialog-title">Host</DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
            color: "#5E72E4",
          }}
        >
          <Tooltip title="Close">
            <Cancel onClick={closePopup} />
          </Tooltip>
        </IconButton>
        {!dialogData && (
          <div className="selectBtn d-flex px-4">
            <button
              className="btn btn-primary w-25"
              onClick={() => {
                setType("single");
              }}
              style={{ backgroundColor: type === "single" && "#2f4ae0" }}
            >
              Single Host
            </button>
            <button
              className="btn btn-primary w-25"
              onClick={() => {
                setType("multiple");
              }}
              style={{ backgroundColor: type === "multiple" && "#2f4ae0" }}
            >
              Multiple Host
            </button>
          </div>
        )}

        <DialogContent>
          <div class="modal-body pt-1 px-1 pb-3">
            <div class="d-flex flex-column text-center">
              <form encType="multipart/form-data">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="float-left">Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Name"
                        required
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              name: "Name can't be a blank!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              name: "",
                            });
                          }
                        }}
                      />
                      {errors.name && (
                        <div class="pl-1 text-left">
                          <Typography variant="caption" color="error">
                            {errors.name}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div class="col-md-6">
                    <div class="form-group">
                      <label class="float-left">UserName</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Username"
                        required
                        value={username}
                        onChange={(e) => {
                          setUserName(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              username: "User Name can't be a blank!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              username: "",
                            });
                          }
                        }}
                      />
                      {errors.username && (
                        <div class="pl-1 text-left">
                          <Typography variant="caption" color="error">
                            {errors.username}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="float-left">Image</label>
                      <input
                        class="form-control"
                        type="file"
                        required=""
                        onChange={handleInputImage}
                        multiple={type === "multiple" ? true : false}
                        formEncType
                      />
                      {errors.image && (
                        <div class="pl-1 text-left">
                          <Typography variant="caption" color="error">
                            {errors.image}
                          </Typography>
                        </div>
                      )}
                      <div className="row mb-0 ml-2">
                        {imagePath && (
                          <Fragment>
                            {imagePath?.map((res) => {
                              return (
                                <img
                                  src={res}
                                  className="mt-3 rounded float-left mb-2"
                                  height="100px"
                                  alt="hostImage"
                                  width="100px"
                                />
                              );
                            })}
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="float-left">
                        Video ( File Limit 100MB )
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        required=""
                        onChange={handleInputVideo}
                        multiple={type === "multiple" ? true : false}
                      />
                      {errors.video && (
                        <div class="pl-1 text-left">
                          <Typography variant="caption" color="error">
                            {errors.video}
                          </Typography>
                        </div>
                      )}
                      <div className="row mb-0 ml-2">
                        {videoPath_ === true && videoPath != "" ? (
                          videoPath?.map((res) => {
                            return (
                              <video
                                src={res}
                                class="mt-3 rounded float-left mb-2"
                                height="100px"
                                width="100px"
                                autoPlay
                              ></video>
                            );
                          })
                        ) : (
                          <video
                            src={videoPath}
                            class="mt-3 rounded float-left mb-2"
                            height="100px"
                            width="100px"
                            autoPlay
                          ></video>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-6">
                    <label class="float-left">Agency</label>

                    <select
                      class="form-select form-control"
                      aria-label="Default select example"
                      value={agency}
                      onChange={(e) => {
                        setAgency(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            agency: "Please select an Agency!",
                          });
                        } else if (e.target.value == "Agency") {
                          return setError({
                            ...errors,
                            agency: "Please select an Agency!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            agency: "",
                          });
                        }
                      }}
                    >
                      <option selected>Agency</option>
                      {AgencyList.map((agency) => {
                        return (
                          <option value={agency._id}>{agency.name}</option>
                        );
                      })}
                    </select>
                    {errors.agency && (
                      <div class="pl-1 text-left">
                        <Typography variant="caption" color="error">
                          {errors.agency}
                        </Typography>
                      </div>
                    )}
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="float-left">Country</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Country"
                        required
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              country: "Country can't be a blank!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              country: "",
                            });
                          }
                        }}
                      />
                      {errors.country && (
                        <div class="pl-1 text-left">
                          <Typography variant="caption" color="error">
                            {errors.country}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div class="col-md-3 pl-0">
                    <label class="float-right text-white"> AUTO </label>

                    <button
                      type="button"
                      class="btn btn-fill btn-warning btn-sm ml-2 mt-lg-2 float-left"
                      style={{ borderRadius: 5 }}
                      onClick={createHostId}
                    >
                      Auto Generate
                    </button>
                  </div> */}
                </div>

                <div class="form-group ">
                  <label class="float-left">Bio</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter bio"
                    required
                    value={bio}
                    onChange={(e) => {
                      setBio(e.target.value);

                      if (!e.target.value) {
                        return setError({
                          ...errors,
                          bio: "Bio can't be a blank!",
                        });
                      } else {
                        return setError({
                          ...errors,
                          bio: "",
                        });
                      }
                    }}
                  />
                  {errors.bio && (
                    <div class="pl-1 text-left">
                      <Typography variant="caption" color="error">
                        {errors.bio}
                      </Typography>
                    </div>
                  )}
                </div>

                <div class="row d-flex">
                  {/* <div class="col-md-6">
                    <div class="form-group">
                      <label class="float-left">Host Id</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter host id"
                        value={username}
                        readOnly={dialogData?.username ? true : false}
                        onChange={(e) => {
                          setUserName(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              username: "Host Id can't be a blank!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              username: "",
                            });
                          }
                        }}
                      />
                      {errors.username && (
                        <div class="pl-1 text-left">
                          <Typography variant="caption" color="error">
                            {errors.username}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div> */}
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="float-left">Password</label>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            {password.show ? (
                              <i
                                class="fas fa-eye icon"
                                onClick={handleShowPassword}
                              ></i>
                            ) : (
                              <i
                                class="fas fa-eye-slash icon"
                                onClick={handleShowPassword}
                              ></i>
                            )}
                          </span>
                        </div>
                        <input
                          type={password.show ? "text" : "password"}
                          class="form-control"
                          placeholder="Enter Password"
                          required
                          autoCorrect={false}
                          value={password.value}
                          onChange={(e) => {
                            setPassword({ value: e.target.value });

                            if (!e.target.value) {
                              return setError({
                                ...errors,
                                password: "Password can't be a blank!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                password: "",
                              });
                            }
                          }}
                        />
                      </div>
                      {errors.password && (
                        <div class="pl-1 text-left">
                          <Typography variant="caption" color="error">
                            {errors.password}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div class="col-md-3 pl-0">
                    <label class="float-right text-white"> AUTO </label>

                    <button
                      type="button"
                      class="btn btn-fill btn-warning btn-sm ml-2 mt-lg-2 float-left"
                      style={{ borderRadius: 5 }}
                      onClick={createPassword}
                    >
                      Auto Generate
                    </button>
                  </div> */}
                </div>
                <button
                  type="button"
                  class={`btn btn-primary btn-block btn-round ${
                    dialogData ? "" : type == "" && "d-none"
                  }`}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default connect(null, {
  createNewHost,
  editFakeHost,
  createMultipleHost,
  getAgency,editHost
})(HostDialog);
