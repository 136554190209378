import React, { Fragment, useState, useEffect } from "react";



//redux
import { useSelector, useDispatch, connect } from "react-redux";
import { CLOSE_REQUEST_DIALOG } from "../../store/pendingHostRequest/types";
import {
  getPendingRequest,
  acceptPendingRequest,
  updateHostRequest,
} from "../../store/pendingHostRequest/action";
import { getHost } from "../../store/host/action";
import { getAgency } from "../../store/agency/action";

//server path
import { baseURL } from "../../util/serverPath";

//custom css
import "../../dist/css/style.min.css";
import "../../dist/css/style.css";

//custom javascript
import "../../dist/js/custom.min.js";
import "../../dist/js/app-style-switcher";
import "../../dist/js/sidebarmenu";
import "../../dist/js/feather.min.js";
import "../../assets/libs/perfect-scrollbar/dist/perfect-scrollbar.jquery.min.js";

//icon
import Cancel from "@material-ui/icons/Cancel";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";

//dialog
import Dialog from "@material-ui/core/Dialog";

const RequestDialog = (props) => {
  const dispatch = useDispatch();
 

  const {
    dialog: open,
    dialogData,
    request,
  } = useSelector((state) => state.request);

  const host = useSelector((state) => state.host.host);

  const AgencyList = useSelector((state) => state.agency.agency);

  const [imageData, setImageData] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [videoData, setVideoData] = useState([]);
  const [videoPath, setVideoPath] = useState("");
  const [mongoId, setMongoId] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [username, setUserName] = useState("");
  const [bio, setBio] = useState("");
  const [agency, setAgency] = useState("");
  const [dob, setDob] = useState();
  const [password, setPassword] = useState({
    value: "",
    show: false,
  });
  const [hostId, setHostId] = useState("");
  const [type, setType] = useState("");

  const [errors, setError] = useState({
    hostId: "",
    password: "",
    name: "",
    image: "",
    username: "",
    bio: "",
    agency: "",
  });

  useEffect(() => {
    props.getHost();
  }, []);

  useEffect(() => {
    props.getAgency();
  }, []);

  console.log("dialogData", dialogData);

  useEffect(() => {
    if (dialogData) {
      setMongoId(dialogData?._id);

      setImagePath(baseURL + "/" + dialogData?.newImage);
      setVideoPath(baseURL + "/" + dialogData?.video);

      setDob(dialogData?.dob);
    }
  }, [dialogData]);
  // useEffect(
  //   () => () => {
  //     setError({
  //       hostId: "",
  //       password: "",
  //       agency: "",
  //     });
  //     setMongoId("");
  //     setHostId("");
  //     setAgency("");
  //     setPassword("");
  //   },
  //   [open]
  // );

  const handleShowPassword = () => {
    setPassword({ ...password, show: !password.show });
  };

  const handleInputImage = (e) => {
    setImageData(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = () => {
    if (!dob) {
      let error = {};
      if (!dob) error.dob = "Dob is Required !";

      if (imageData.length === 0) error.image = "Image is Required!";
      if (videoData.length === 0) error.video = "Video is Required!";
    } else {
      const formData = new FormData();
      formData.append("dob", dob);
      formData.append("newImage", imageData);
      formData.append("video", videoData);

      console.log("dob", dob);
      console.log("newImage", imageData);
      console.log("video", videoData);
      props.updateHostRequest(formData, mongoId);
      closePopup();
    }
  };
  const closePopup = () => {
    dispatch({ type: CLOSE_REQUEST_DIALOG });
  };
  var fileSize = 0;

  const handleInputVideo = (e) => {
    setVideoData(e.target.files[0]);
    setVideoPath(URL.createObjectURL(e.target.files[0]));
    
  };

  const videoPath_ = Array.isArray(videoPath);
  return (
    <Fragment>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="responsive-dialog-title">request Dialogue</DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
            color: "#5E72E4",
          }}
        >
          <Tooltip title="Close">
            <Cancel onClick={closePopup} />
          </Tooltip>
        </IconButton>

        <DialogContent>
          <div class="modal-body pt-1 px-1 pb-3">
            <div class="d-flex flex-column text-center">
              <form encType="multipart/form-data">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="float-left">Dob</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Name"
                        required
                        value={dob}
                        onChange={(e) => {
                          setDob(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              dob: "Name can't be a blank!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              dob: "",
                            });
                          }
                        }}
                      />
                      {errors.dob && (
                        <div class="pl-1 text-left">
                          <Typography variant="caption" color="error">
                            {errors.dob}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="float-left">Image</label>
                      <input
                        class="form-control"
                        type="file"
                        accept="image/*"
                        required=""
                        onChange={handleInputImage}
                        multiple={type === "multiple" ? true : false}
                        formEncType
                      />
                      {errors.image && (
                        <div class="pl-1 text-left">
                          <Typography variant="caption" color="error">
                            {errors.image}
                          </Typography>
                        </div>
                      )}
                      <div className="row mb-0 ml-2">
                        {imagePath && (
                          <Fragment>
                            {imagePath && (
                              <img
                                src={imagePath}
                                className="mt-3 rounded float-left mb-2"
                                height="100px"
                                alt="hostImage"
                                width="100px"
                              />
                            )}
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="float-left">
                        Video ( File Limit 100MB )
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        required=""
                        onChange={handleInputVideo}
                        multiple={type === "multiple" ? true : false}
                      />
                      {errors.video && (
                        <div class="pl-1 text-left">
                          <Typography variant="caption" color="error">
                            {errors.video}
                          </Typography>
                        </div>
                      )}
                      <div className="row mb-0 ml-2">
                        {videoPath_ === true && videoPath != "" ? (
                          videoPath?.map((res) => {
                            return (
                              <>
                                <video
                                  src={res}
                                  class="mt-3 rounded float-left mb-2"
                                  height="100px"
                                  width="100px"
                                  autoPlay
                                ></video>
                              </>
                            );
                          })
                        ) : (
                          <video
                            src={videoPath}
                            class="mt-3 rounded float-left mb-2"
                            height="125px"
                            width="125px"
                            autoPlay
                          ></video>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="button"
                  class={`btn btn-primary btn-block btn-round ${
                    dialogData ? "" : type == "" && "d-none"
                  }`}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default connect(null, {
  getPendingRequest,
  updateHostRequest,
  getHost,
  acceptPendingRequest,
  getAgency,
})(RequestDialog);
