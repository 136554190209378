import {
  GET_HOST,
  GET_FAKE_HOST,
  EDIT_FAKE_HOST,
  CREATE_MULTIPLE_HOST,
  BLOCK_UNBLOCK_HOST,
  OPEN_HOST_DIALOG,
  CLOSE_HOST_DIALOG,
  CREATE_NEW_HOST,
  EDIT_HOST,
  SET_CREATE_HOST_DONE,
  UNSET_CREATE_HOST_DONE,
  SET_UPDATE_HOST_DONE,
  UNSET_UPDATE_HOST_DONE,
  GET_HOST_ANALYTIC,
  GET_SINGLE_HOST_DATA,
  GET_TOTAL_COIN_OF_ANALYTIC,
  GET_LIVE_STREAMING_ANALYTIC,
  GET_LIVE_STREAMING_COIN,
  EXTRA_BONUS,
  GET_AGENCY_WISE_HOST,
  GET_HOST_SCREENSHOT,
  GET_HOST_FACE_RECOGNIZE_VIDEO,
  GET_ANCHOR_REPORT,
  GET_HOST_SETTLEMENT_HISTORY,
  LIVE,
  DELETE_FAKE_HOST,
  VIDEO,
  GET_HOST_WEEK_WISE_SETTLEMENT_HISTORY,
} from "./types";

const initialState = {
  agencyWiseHost: [],
  host: [],
  fakeHost: [],
  anchorReport: {},
  dialog: false,
  dialogData: null,
  createDone: false,
  updateDone: false,
  analytic: [],
  singleHost: {},
  totalCoin: {},
  liveStreamingAnalytic: [],
  liveStreamingCoin: null,
  screenshot: [],
  hostFaceRecognize: {},
  hostSettlementHistory: [],
  hostWeekWiseSettlement:[],
};

const hostReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AGENCY_WISE_HOST:
      return {
        ...state,
        agencyWiseHost: action.payload,
      };
    case GET_HOST:
      return {
        ...state,
        host: action.payload,
      };
    case GET_FAKE_HOST:
      return {
        ...state,
        fakeHost: action.payload,
      };
    case GET_ANCHOR_REPORT:
      return {
        ...state,
        anchorReport: action.payload,
      };
    case BLOCK_UNBLOCK_HOST:
      return {
        ...state,
        host: state.host.map((host) => {
          if (host._id === action.payload._id)
            return {
              ...host,
              block: action.payload.block,
            };
          else return host;
        }),
      };
    case EXTRA_BONUS:
      return {
        ...state,
        host: state.host.map((host) => {
          if (host._id === action.payload._id)
            return {
              ...host,
              bonusSwitch: action.payload.bonusSwitch,
            };
          else return host;
        }),
      };
    case LIVE:
      return {
        ...state,
        fakeHost: state.fakeHost.map((fakeHost) => {
          if (fakeHost._id === action.payload._id)
            return {
              ...fakeHost,
              showInLive: action.payload.showInLive,
            };
          else return fakeHost;
        }),
      };
    case VIDEO:
      return {
        ...state,
        fakeHost: state.fakeHost.map((fakeHost) => {
          if (fakeHost._id === action.payload._id)
            return {
              ...fakeHost,
              showInVideo: action.payload.showInVideo,
            };
          else return fakeHost;
        }),
      };
    case CREATE_NEW_HOST:
      const data = [...state.fakeHost];
      console.log("state.host", data);
      data.unshift(action.payload);
      return {
        ...state,
        fakeHost: data,
      };
    case CREATE_MULTIPLE_HOST:
      const data_ = [...state.fakeHost, ...action.payload];
      return {
        ...state,
        fakeHost: data_,
      };
    case EDIT_HOST:
      console.log(action.payload);
      return {
        ...state,
        host: state.host.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };
    case EDIT_FAKE_HOST:
      return {
        ...state,
        fakeHost: state.fakeHost.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };

    case SET_CREATE_HOST_DONE:
      return {
        ...state,
        createDone: true,
      };
    case UNSET_CREATE_HOST_DONE:
      return {
        ...state,
        createDone: false,
      };
    case SET_UPDATE_HOST_DONE:
      return {
        ...state,
        updateDone: true,
      };
    case UNSET_UPDATE_HOST_DONE:
      return {
        ...state,
        updateDone: false,
      };
    case OPEN_HOST_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_HOST_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };
    case GET_HOST_ANALYTIC:
      return {
        ...state,
        analytic: action.payload,
      };

    case GET_LIVE_STREAMING_ANALYTIC:
      return {
        ...state,
        liveStreamingAnalytic: action.payload,
      };
    case GET_TOTAL_COIN_OF_ANALYTIC:
      return {
        ...state,
        totalCoin: action.payload,
      };
    case GET_LIVE_STREAMING_COIN:
      return {
        ...state,
        liveStreamingCoin: action.payload,
      };
    case GET_SINGLE_HOST_DATA:
      return {
        ...state,
        singleHost: action.payload,
      };

    //Get Host screenshot
    case GET_HOST_SCREENSHOT:
      return {
        ...state,
        screenshot: action.payload,
      };

    //Get Host Face Recognize
    case GET_HOST_FACE_RECOGNIZE_VIDEO:
      return {
        ...state,
        hostFaceRecognize: action.payload,
      };

    //Get Host Settlement History
    case GET_HOST_SETTLEMENT_HISTORY:
      return {
        ...state,
        hostSettlementHistory: action.payload,
      };

    case DELETE_FAKE_HOST:
      return {
        ...state,
        fakeHost: state.fakeHost.filter(
          (fakeHost) => fakeHost._id !== action.payload
        ),
      };
      case GET_HOST_WEEK_WISE_SETTLEMENT_HISTORY :
        return {
          ...state,
          hostWeekWiseSettlement:action.payload 
        }

    default:
      return state;
  }
};

export default hostReducer;
