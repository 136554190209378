// (window, $);
import React, { Fragment, useEffect, useState } from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import TablePaginationActions from "./TablePagination";

import {
  getHostImage,
  deleteHostImage,
  isApproveSwitch,
  isApproveSwitchProfile,
  getHostProfile,
  deleteAllImage,
  hostSingleImage,
} from "../../store/hostImage/action";

//custom css
import "../../dist/css/style.min.css";
import "../../dist/css/style.css";

import { Snackbar, TablePagination } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { baseURL } from "../../util/serverPath";
import dayjs from "dayjs";
import { warning } from "../../util/alert";

function HostImageTable(props) {
  const { hostImage, hostImageProfile, hostSingleImage } = useSelector(
    (state) => state.hostImage
  );

  const [data, setData] = useState([]);
  const [dataSingleImage, setDataSingleImage] = useState([]);
  const [dataProfile, setDataProfile] = useState([]);
  const [pageProfile, setPageProfile] = useState(0);
  const [rowsPerPageProfile, setRowsPerPageProfile] = useState(5);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    props.getHostProfile();
    props.getHostImage();
  }, []);

  useEffect(() => {
    setDataSingleImage(hostSingleImage);
  }, [hostSingleImage]);

  useEffect(() => {
    setData(hostImage);
  }, [hostImage]);
  useEffect(() => {
    setDataProfile(hostImageProfile);
  }, [hostImageProfile]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPageProfile = (event) => {
    setRowsPerPageProfile(parseInt(event.target.value, 10));
    setPageProfile(0);
  };

  const handleChangePageProfile = (event, newPage) => {
    setPageProfile(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (id, type) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          if (type === "All") {
            props.deleteAllImage(id, type);
          } else {
            props.deleteHostImage(id, type);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleApproveImage = (imageAllId) => {
    setOpenSuccess(true);
    props.isApproveSwitch(imageAllId, "All");
  };

  const handleApproveProfile = (data) => {
    setOpenSuccess(true);
    props.isApproveSwitchProfile(data);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase();
    if (value) {
      const data = hostImageProfile.filter((data) => {
        return (
          data.hostname?.toUpperCase()?.indexOf(value) > -1 ||
          data.country?.toUpperCase()?.indexOf(value) > -1
        );
      });
      setDataProfile(data);
    } else {
      return setDataProfile(hostImageProfile);
    }
  };

  const handleSearchImage = (e) => {
    const value = e.target.value.toUpperCase();
    if (value) {
      const data = hostImage.filter((data) => {
        return (
          data.hostname?.toUpperCase()?.indexOf(value) > -1 ||
          data.country?.toUpperCase()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      return setData(hostImage);
    }
  };
  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleOpenURl = (url) => {
    window.open(url, "_blank");
  };

  const handleShowImage = (id) => {
    setOpen(true);
    props.hostSingleImage(id);
  };

  const handleDeleteSingle = (id) => {
    props.deleteHostImage(id, "SingleImage");
  };

  const handleApproveSingleImage = (image) => {
    setOpenSuccess(true);
    props.isApproveSwitch(image, "SingleImage");
  };
  return (
    <Fragment>
      <Snackbar
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSuccess} severity="success">
          <span style={{ color: "#184d47" }}>
            <b>Success!</b> Image IsApprove successfully.
          </span>
        </Alert>
      </Snackbar>
      <div class="page-breadcrumb">
        <div class="row">
          <div class="col-7 align-self-center">
            <div class="d-flex align-items-center">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0 p-0">
                  <li class="breadcrumb-item">
                    <a href="index.html" class="text-muted">
                      Home
                    </a>
                  </li>
                  <li
                    class="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Image
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8 mt-4 float-left">
                    <h3 class="card-title">Host Profile</h3>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-3 float-right">
                    <form action="">
                      <div class="input-group mb-4 border rounded-pill p-1">
                        <div class="input-group-prepend border-0">
                          <div
                            id="button-addon4"
                            class="btn btn-link text-primary"
                          >
                            <i class="fa fa-search"></i>
                          </div>
                        </div>
                        <input
                          type="search"
                          placeholder="What're you searching for?"
                          aria-describedby="button-addon4"
                          class="form-control bg-none border-0 rounded-pill mr-1"
                          onChange={handleSearch}
                        />
                      </div>
                    </form>
                  </div>
                </div>
                {/* isProfile true  */}
                <div class="table-responsive mb-5">
                  <table
                    id="zero_config"
                    class="table table-striped table-bordered no-wrap"
                  >
                    <thead>
                      <tr>
                        <th>New Image</th>
                        <th>Old Image</th>
                        <th>Name</th>
                        <th>Host Id</th>
                        <th>Country</th>
                        <th>Created At</th>

                        <th>Is Approve</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataProfile?.length > 0 ? (
                        (rowsPerPageProfile > 0
                          ? dataProfile?.slice(
                              pageProfile * rowsPerPageProfile,
                              pageProfile * rowsPerPageProfile +
                                rowsPerPageProfile
                            )
                          : dataProfile
                        )?.map((data, i) => {
                          return (
                            <>
                              <tr
                                key={i}
                                data-toggle="collapse"
                                data-target={`#profile${i}`}
                                className="text-center accordion-toggle"
                              >
                                <td>
                                  {
                                    <img
                                      src={data.image}
                                      width="70px"
                                      height="70px"
                                      alt="img"
                                      style={{
                                        objectFit: "contain",
                                        borderRadius: "50%",
                                        border: " 1px solid #808080",
                                      }}
                                      class="mr-3"
                                      onClick={() => handleOpenURl(data.image)}
                                    />
                                  }
                                </td>
                                <td>
                                  <img
                                    src={data.hostImage}
                                    width="70px"
                                    height="70px"
                                    alt="img"
                                    style={{
                                      objectFit: "contain",
                                      borderRadius: "50%",
                                      border: " 1px solid #808080",
                                    }}
                                    class="mr-3"
                                    onClick={() =>
                                      handleOpenURl(data.hostImage)
                                    }
                                  />
                                </td>

                                <td>{data?.hostname}</td>
                                <td>{data?.hostId}</td>
                                <td>{data?.country}</td>
                                <td>
                                  {dayjs(data?.createdAt).format(
                                    "DD MMM, YYYY"
                                  )}
                                </td>

                                <td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    <label class="switch">
                                      <input
                                        type="checkbox"
                                        checked={data?.isAccept}
                                        onChange={() =>
                                          handleApproveProfile(data._id)
                                        }
                                      />
                                      <span class="slider">
                                        <p
                                          style={{
                                            fontSize: 12,
                                            marginLeft: `${
                                              data.isAccepted ? "5px" : "33px"
                                            }`,
                                            color: "white",
                                            marginTop: "6px",
                                          }}
                                        >
                                          {data?.isAccepted ? "Yes" : "No"}
                                        </p>
                                      </span>
                                    </label>
                                  </td>
                                </td>
                                <td className="text-center">
                                  <a
                                    onClick={() => handleDelete(data)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <i class="fas fa-trash-alt text-danger"></i>
                                  </a>
                                </td>
                              </tr>
                              {/* <tr>
                                <td colspan="12" class="hiddenRow">
                                  <div
                                    class="accordian-body collapse subReport"
                                    id={`profile${i}`}
                                  >
                                    <div className="table-responsive table-height">
                                      <table class="table table-striped">
                                        <thead
                                          className="sticky-top"
                                          style={{ top: "-1px" }}
                                        >
                                          <tr className="text-center ">
                                            <th>New Image</th>
                                            <th>Old Image</th>
                                            <th>Name</th>
                                            <th>Host Id</th>
                                            <th>Country</th>
                                            <th>Created At</th>
                                            <th>Is Approve</th>
                                            <th>Delete</th>
                                          </tr>
                                        </thead>

                                        <tbody className="text-capitalize">
                                          {data?.reports?.map((image, index) => {
                                            return (
                                              <>
                                                <tr
                                                  className="text-center  py-2"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #eef2f6",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  <td className="p-0">
                                                    {index + 1}1
                                                  </td>
                                                  <td className="py-2">
                                                    <img
                                                      src={image?.image}
                                                      alt="Profile"
                                                      style={{
                                                        width: "50px",
                                                        height: "50px",
                                                      }}
                                                      className="table_image m-auto fs-16"
                                                    />
                                                  </td>

                                                  <td className="py-2 fs-16 text-danger fw-bolder">
                                                    {image?.post?.gift}
                                                  </td>
                                                  <td className="py-2 fs-16 text-success fw-bolder">
                                                    {image?.post?.like}
                                                  </td>
                                                  <td
                                                    className="py-2 fs-16 "
                                                    style={{ color: "#bb1e1e" }}
                                                  >
                                                    {image?.report}
                                                  </td>
                                                  <td className="py-2 fs-16"></td>
                                                </tr>
                                              </>
                                            );
                                          })}
                                          <tr className="text-center ">
                                            <th>New Image</th>
                                            <th>Old Image</th>
                                            <th>Name</th>
                                            <th>Host Id</th>
                                            <th>Country</th>
                                            <th>Created At</th>
                                            <th>Is Approve</th>
                                            <th>Delete</th>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                              </tr> */}
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="8" align="center">
                            Nothing to show!!
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>New Image</th>
                        <th>Old Image</th>
                        <th>Name</th>
                        <th>Host Id</th>
                        <th>Country</th>
                        <th>Created At</th>
                        <th>Is Approve</th>
                        <th>Delete</th>
                      </tr>
                    </tfoot>
                  </table>
                  <div class="py-2 d-flex justify-content-end">
                    <TablePagination
                      style={{ borderBottom: "none" }}
                      id="pagination"
                      imageponent="div"
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      count={dataProfile.length}
                      rowsPerPage={rowsPerPageProfile}
                      page={pageProfile}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onChangePage={handleChangePageProfile}
                      onChangeRowsPerPage={handleChangeRowsPerPageProfile}
                      Actionsimageponent={TablePaginationActions}
                    />
                  </div>
                </div>
                {/* isProfile false */}

                <div class="row mb-5">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8 mt-4 float-left">
                    <h3 class="card-title">Image</h3>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-3 float-right">
                    <form action="">
                      <div class="input-group mb-4 border rounded-pill p-1">
                        <div class="input-group-prepend border-0">
                          <div
                            id="button-addon4"
                            class="btn btn-link text-primary"
                          >
                            <i class="fa fa-search"></i>
                          </div>
                        </div>
                        <input
                          type="search"
                          placeholder="What're you searching for?"
                          aria-describedby="button-addon4"
                          class="form-control bg-none border-0 rounded-pill mr-1"
                          onChange={handleSearchImage}
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div class="table-responsive ">
                  <table
                    id="zero_config"
                    class="table table-striped table-bordered no-wrap"
                  >
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Host Id</th>
                        <th>Country</th>
                        <th>Created At</th>
                        <th>Show Image</th>
                        <th>Is Approve</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 ? (
                        (rowsPerPage > 0
                          ? data?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : data
                        )?.map((data, ids) => {
                          return (
                            <>
                              <tr
                                key={ids}
                                data-toggle="collapse"
                                data-target={`#demo${ids}`}
                                className="text-center accordion-toggle"
                              >
                                <td>
                                  {
                                    <img
                                      src={data?.profile}
                                      width="70px"
                                      height="70px"
                                      alt="img"
                                      style={{
                                        objectFit: "contain",
                                        borderRadius: "50%",
                                        border: " 1px solid #808080",
                                      }}
                                      class="mr-3"
                                      onClick={() =>
                                        handleOpenURl(data?.profile)
                                      }
                                    />
                                  }
                                </td>
                                <td>{data?.hostname}</td>
                                <td>{data?.hostId}</td>
                                <td>{data?.country}</td>
                                <td>
                                  {dayjs(data?.createdAt).format(
                                    "DD MMM, YYYY"
                                  )}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-info"
                                    onClick={() => handleShowImage(data._id)}
                                  >
                                    Show Image
                                  </button>
                                </td>
                                <td>
                                  <td style={{ verticalAlign: "middle" }}>
                                    <label class="switch">
                                      <input
                                        type="checkbox"
                                        checked={data?.isAccept}
                                        onChange={() =>
                                          handleApproveImage(data, "All")
                                        }
                                      />
                                      <span class="slider">
                                        <p
                                          style={{
                                            fontSize: 12,
                                            marginLeft: `${
                                              data?.isAccepted ? "5px" : "33px"
                                            }`,
                                            color: "white",
                                            marginTop: "6px",
                                          }}
                                        >
                                          {data?.isAccepted ? "Yes" : "No"}
                                        </p>
                                      </span>
                                    </label>
                                  </td>
                                </td>
                                <td className="text-center">
                                  <a
                                    href={() => false}
                                    onClick={() => handleDelete(data, "All")}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <i class="fas fa-trash-alt text-danger"></i>
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="12" class="hiddenRow">
                                  <div
                                    class="accordian-body collapse subReport"
                                    id={open && `demo${ids}`}
                                  >
                                    <div className="table-responsive table-height">
                                      <table className="table table-striped ">
                                        <thead
                                          className="sticky-top"
                                          style={{ top: "-1px" }}
                                        >
                                          <tr className="text-center">
                                            <th>New Image</th>
                                            <th>Name</th>
                                            <th>Host Id</th>
                                            <th>Country</th>
                                            <th>Created At</th>

                                            <th>Is Approve</th>
                                            <th>Delete</th>
                                          </tr>
                                        </thead>

                                        <tbody className="text-capitalize">
                                          {hostSingleImage?.map(
                                            (image, index) => {
                                              return (
                                                <>
                                                  <tr
                                                    className="text-center  py-2"
                                                    style={{
                                                      borderBottom:
                                                        "1px solid #eef2f6",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    <td className="py-2">
                                                      <img
                                                        src={image?.image}
                                                        alt="Profile"
                                                        style={{
                                                          width: "50px",
                                                          height: "50px",
                                                        }}
                                                        className="table_image m-auto"
                                                        onClick={() =>
                                                          handleOpenURl(
                                                            image?.image
                                                          )
                                                        }
                                                      />
                                                    </td>

                                                    <td className="py-2   ">
                                                      {image?.hostname}
                                                    </td>
                                                    <td className="py-2   ">
                                                      {image?.hostId}
                                                    </td>
                                                    <td className="py-2 ">
                                                      {image?.country}
                                                    </td>
                                                    <td className="py-2">
                                                      {dayjs(
                                                        data?.createdAt
                                                      ).format("DD MMM YYYY")}
                                                    </td>
                                                    <td>
                                                      <td
                                                        style={{
                                                          verticalAlign:
                                                            "middle",
                                                        }}
                                                      >
                                                        <label class="switch">
                                                          <input
                                                            type="checkbox"
                                                            checked={
                                                              image.isAccept
                                                            }
                                                            onChange={() =>
                                                              handleApproveSingleImage(
                                                                image
                                                              )
                                                            }
                                                          />
                                                          <span class="slider">
                                                            <p
                                                              style={{
                                                                fontSize: 12,
                                                                marginLeft: `${
                                                                  image.isAccepted
                                                                    ? "5px"
                                                                    : "33px"
                                                                }`,
                                                                color: "white",
                                                                marginTop:
                                                                  "6px",
                                                              }}
                                                            >
                                                              {image?.isAccepted
                                                                ? "Yes"
                                                                : "No"}
                                                            </p>
                                                          </span>
                                                        </label>
                                                      </td>
                                                    </td>
                                                    <td className="text-center">
                                                      <a
                                                        href={() => false}
                                                        onClick={() =>
                                                          handleDeleteSingle(
                                                            image
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        <i class="fas fa-trash-alt text-danger"></i>
                                                      </a>
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            }
                                          )}
                                          <tr className="text-center ">
                                            <th>New Image</th>
                                            <th>Name</th>
                                            <th>Host Id</th>
                                            <th>Country</th>
                                            <th>Created At</th>
                                            <th>Is Approve</th>
                                            <th>Delete</th>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="8" align="center">
                            Nothing to show!!
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Host Id</th>
                        <th>Country</th>
                        <th>Created At</th>
                        <th>Show Image</th>
                        <th>Is Approve</th>
                        <th>Delete</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="py-2 d-flex justify-content-end ">
                  <TablePagination
                    style={{ borderBottom: "none" }}
                    id="pagination"
                    imageponent="div"
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    Actionsimageponent={TablePaginationActions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default connect(null, {
  getHostImage,
  deleteHostImage,
  isApproveSwitch,
  getHostProfile,
  isApproveSwitchProfile,
  deleteAllImage,
  hostSingleImage,
})(HostImageTable);
