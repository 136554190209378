// (window, $);
import React, { Fragment, useEffect, useState } from "react";

//router
import { Link } from "react-router-dom";

//category dialog
import AgencyDialog from "../Dialog/agencyDialog";

//dayjs
import dayjs from "dayjs";

//alert
import { warning, alert } from "../../util/alert";

//redux
import { useDispatch, connect, useSelector } from "react-redux";
import { getAgency, enableDisableAgency } from "../../store/agency/action";
import {
  OPEN_AGENCY_DIALOG,
  UNSET_CREATE_AGENCY_DONE,
  UNSET_UPDATE_AGENCY_DONE,
} from "../../store/agency/types";

//datatable
import $ from "jquery";

//custom css
import "../../dist/css/style.min.css";
import "../../dist/css/style.css";

//MUI
import { Snackbar, TablePagination } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import TablePaginationActions from "./TablePagination";

//server path
import { baseURL } from "../../util/serverPath";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx";


const AgencyTable = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openUpdateSuccess, setOpenUpdateSuccess] = useState(false);
  const [openDeleteCount, setOpenDeleteCount] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { agency, createDone, updateDone } = useSelector(
    (state) => state.agency
  );

 

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (createDone) {
      setOpenSuccess(true);
      dispatch({ type: UNSET_CREATE_AGENCY_DONE });
    }
  }, [createDone, dispatch]);
  useEffect(() => {
    if (updateDone) {
      setOpenUpdateSuccess(true);
      dispatch({ type: UNSET_UPDATE_AGENCY_DONE });
    }
  }, [updateDone, dispatch]);

  useEffect(() => {
    setData(agency);
  }, [agency]);

  // $(document).ready(function () {
  //   $("#zero_config").DataTable();
  //   $(".dataTables_empty").empty();
  // });

  useEffect(() => {
    props.getAgency();
  }, []);

  const handleOpen = () => {
    // 
    dispatch({ type: OPEN_AGENCY_DIALOG });
  };

  const handleEdit = (data) => {
    // 
    dispatch({ type: OPEN_AGENCY_DIALOG, payload: data });
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };
  const handleCloseUpdateSuccess = () => {
    setOpenUpdateSuccess(false);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase();
    if (value) {
      const data = agency.filter((data) => {
        return (
          data?.name?.toUpperCase()?.indexOf(value) > -1 ||
          data?.email?.toUpperCase()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      return setData(agency);
    }
  };

  const handleDisable = (data) => {
    
    props.enableDisableAgency(data._id);
  };

  const showHost = (id) => {
    localStorage.setItem("agencyId", id);
    history.push("/admin/host");
  };

  //Handle Open Settlement Report Table
  const handleOpenAgencySettlement = (id) => {
    history.push({
      pathname: "/admin/agency/agencySettlement",
      state: { id: id },
    });
  };
  //Handle Open Settlement Report Table
  const handleOpenAgencySettlementHistory = (id) => {
    history.push({
      pathname: "/admin/host/hostWeekWiseSettlement",
      state: { id: id },
    });
  };

  const handleDownloadExcel = () => {
    const excelData = data?.map((user,index)=>({
      No: index + 1,
      Name: user?.name,
      Email: user?.email,
      Country: user?.country,
      Code: user?.code,
      Mobile: user?.mobileNo,
      CreatedAt: dayjs(data.createdAt).format("DD MMM, YYYY"),
      isDisable:user?.isDisable ? "Yes" : "No",

    }))
    const ws = XLSX.utils.json_to_sheet([...excelData]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Agency Data");
    XLSX.writeFile(wb, "agency_data.xlsx");
  }

  return (
    <>
      <Snackbar
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSuccess} severity="success">
          <span style={{ color: "#184d47" }}>
            <b>Success!</b> Agency add successfully.
          </span>
        </Alert>
      </Snackbar>
      <Snackbar
        open={openUpdateSuccess}
        autoHideDuration={3000}
        onClose={handleCloseUpdateSuccess}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseUpdateSuccess} severity="success">
          <span style={{ color: "#184d47" }}>
            <b>Success!</b> Agency update successfully.
          </span>
        </Alert>
      </Snackbar>

      <div class="page-breadcrumb">
        <div class="row">
          <div class="col-7 align-self-center">
            <div class="d-flex align-items-center">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0 p-0">
                  <li class="breadcrumb-item">
                    <Link to="/admin/dashboard" class="text-muted">
                      Home
                    </Link>
                  </li>
                  <li
                    class="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Agency
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div class="col-5  ">
          <button
              id="test-table-xls-button"
              className="btn btn-primary float-right"
              table="host-table"
              filename="HostTable"
              sheet="hosts"
              buttonText="Export To Excel"
              onClick={handleDownloadExcel}
            >
              Export To Excel
            </button>
          </div>
        </div>
      </div>

      <table
        id="agency-table"
        class="table table-striped table-bordered no-wrap d-none"
      >
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Email</th>
            <th>Country</th>
            <th>Code</th>
            <th>Mobile Number</th>
            <th>isDisable</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            <Fragment>
              {(rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              ).map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{baseURL + data.image}</td>
                    <td>{data.name}</td>
                    <td>{data.email}</td>
                    <td>{data.country}</td>
                    <td>{data.code}</td>
                    <td>{data.mobileNo}</td>
                    <td style={{ verticalAlign: "middle" }}>
                      {data.isDisable ? "Yes" : "No"}
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      {dayjs(data.createdAt).format("DD MMM, YYYY")}
                    </td>
                  </tr>
                );
              })}
            </Fragment>
          ) : (
            <tr>
              <td colSpan="8" align="center">
                Nothing to show!!
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Email</th>
            <th>Country</th>
            <th>Code</th>
            <th>Created At</th>
            <th>isDisable</th>
            <th>Mobile Number</th>
          </tr>
        </tfoot>
      </table>

      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8 mt-4 float-left">
                    <button
                      type="button"
                      class="btn waves-effect waves-light btn-primary btn-sm"
                      data-toggle="modal"
                      // data-target="#country-modal"
                      style={{ borderRadius: 5 }}
                      onClick={handleOpen}
                    >
                      <i class="fas fa-plus"></i> New
                    </button>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-3 float-right">
                    <form action="">
                      <div class="input-group mb-4 border rounded-pill p-1">
                        <div class="input-group-prepend border-0">
                          <div
                            id="button-addon4"
                            class="btn btn-link text-primary"
                          >
                            <i class="fa fa-search"></i>
                          </div>
                        </div>
                        <input
                          type="search"
                          placeholder="What're you searching for?"
                          aria-describedby="button-addon4"
                          class="form-control bg-none border-0 rounded-pill mr-1"
                          onChange={handleSearch}
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8 mt-3 float-left mb-0"></div>
                </div>
                <div class="table-responsive">
                  <table
                    id="zero_config"
                    class="table table-striped table-bordered no-wrap"
                  >
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Country</th>
                        <th>Code</th>
                        <th>Mobile Number</th>
                        <th>Created At</th>
                        <th>Action</th>
                        <th>Is Disable</th>
                        <th>Host Detail</th>
                        <th>Settlement Report</th>
                        <th>Settlement Report History</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? (
                        <Fragment>
                          {(rowsPerPage > 0
                            ? data.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : data
                          ).map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {
                                    <img
                                      src={baseURL + "/" + data.image}
                                      width="70px"
                                      height="70px"
                                      alt="img"
                                      style={{
                                        objectFit: "contain",
                                        borderRadius: "50%",
                                      }}
                                      class="mr-3"
                                    />
                                  }
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.name}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.email}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.country}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.code}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.mobileNo}
                                </td>

                                <td style={{ verticalAlign: "middle" }}>
                                  {dayjs(data.createdAt).format("DD MMM, YYYY")}
                                </td>

                                <td style={{ verticalAlign: "middle" }}>
                                  <a
                                    class="ml-3"
                                    onClick={() => handleEdit(data)}
                                    style={{ cursor: "pointer" }}
                                    href={() => false}
                                  >
                                    <i class="fas fa-edit text-primary mr-3"></i>
                                  </a>
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      checked={data.isDisable}
                                      onChange={() => handleDisable(data)}
                                    />
                                    <span class="slider">
                                      <p
                                        style={{
                                          fontSize: 12,
                                          marginLeft: `${
                                            data.isDisable ? "5px" : "33px"
                                          }`,
                                          color: "white",
                                          marginTop: "6px",
                                        }}
                                      >
                                        {data.isDisable ? "Yes" : "No"}
                                      </p>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-fill btn-primary btn-sm"
                                    style={{
                                      borderRadius: 5,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => showHost(data._id)}
                                  >
                                    Host Detail
                                  </button>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-fill btn-primary btn-sm"
                                    style={{
                                      borderRadius: 5,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleOpenAgencySettlement(data._id)
                                    }
                                  >
                                    Settlement Report
                                  </button>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-fill btn-primary btn-sm"
                                    style={{
                                      borderRadius: 5,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleOpenAgencySettlementHistory(
                                        data._id
                                      )
                                    }
                                  >
                                    Settlement Report History
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </Fragment>
                      ) : (
                        <tr>
                          <td colSpan="15" align="center">
                            Nothing to show!!
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Country</th>
                        <th>Code</th>
                        <th>Mobile Number</th>
                        <th>Created At</th>
                        <th>Action</th>
                        <th>Is Disable</th>
                        <th>Host Detail</th>
                        <th>Settlement Report</th>
                        <th>Settlement Report History</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="py-2">
                  <TablePagination
                    id="pagination"
                    component="div"
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AgencyDialog />
    </>
  );
};

export default connect(null, {
  getAgency,
  enableDisableAgency,
})(AgencyTable);
