export const GET_HOST = "GET_HOST";

export const GET_FAKE_HOST = "GET_FAKE_HOST";
export const DELETE_FAKE_HOST = "DELETE_FAKE_HOST";

export const CREATE_MULTIPLE_HOST = "CREATE_MULTIPLE_HOST";

export const EDIT_FAKE_HOST = "EDIT_FAKE_HOST";

export const BLOCK_UNBLOCK_HOST = "BLOCK_UNBLOCK_HOST";

export const CREATE_NEW_HOST = "CREATE_NEW_HOST";
export const SET_CREATE_HOST_DONE = "SET_CREATE_HOST_DONE";
export const UNSET_CREATE_HOST_DONE = "UNSET_CREATE_HOST_DONE";

export const OPEN_HOST_DIALOG = "OPEN_HOST_DIALOG";
export const CLOSE_HOST_DIALOG = "CLOSE_HOST_DIALOG";

export const EDIT_HOST = "EDIT_HOST";
export const SET_UPDATE_HOST_DONE = "SET_UPDATE_HOST_DONE";
export const UNSET_UPDATE_HOST_DONE = "UNSET_UPDATE_HOST_DONE";

export const GET_HOST_ANALYTIC = "GET_HOST_ANALYTIC";

export const GET_SINGLE_HOST_DATA = "GET_SINGLE_HOST_DATA";
export const GET_TOTAL_COIN_OF_ANALYTIC = "GET_TOTAL_COIN_OF_ANALYTIC";

export const GET_LIVE_STREAMING_ANALYTIC = "GET_LIVE_STREAMING_ANALYTIC";
export const GET_LIVE_STREAMING_COIN = "GET_LIVE_STREAMING_COIN";

export const EXTRA_BONUS = "EXTRA_BONUS";

//for fake host
export const LIVE = "LIVE";
export const VIDEO = "VIDEO";

export const GET_AGENCY_WISE_HOST = "GET_AGENCY_WISE_HOST";

//Host Work Screenshot
export const GET_HOST_SCREENSHOT = "GET_HOST_SCREENSHOT";

//Get Face Recognize for host
export const GET_HOST_FACE_RECOGNIZE_VIDEO = "GET_HOST_FACE_RECOGNIZE_VIDEO";

//anchore report
export const GET_ANCHOR_REPORT = "GET_ANCHOR_REPORT";

//Get Host Settlement History Report
export const GET_HOST_SETTLEMENT_HISTORY = "GET_HOST_SETTLEMENT_HISTORY";

//Get Host week wise Settlement History Report
export const GET_HOST_WEEK_WISE_SETTLEMENT_HISTORY = "GET_HOST_WEEK_WISE_SETTLEMENT_HISTORY";
