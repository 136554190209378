import React from "react";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import $ from "jquery";
import imageShow from "../../assets/images/default.jpg";

//icon
import Cancel from "@material-ui/icons/Cancel";
//dialog
import Dialog from "@material-ui/core/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_IMAGE_DIALOG } from "../../store/image/types";
import { baseURL } from "../../util/serverPath";

function ShowImageHostRequest(props) {
  const { imageDialogue: open, imageDialogueData } = useSelector(
    (state) => state.request
  );
  console.log("imageDialogueData", imageDialogueData);

  const dispatch = useDispatch();

  const closePopup = () => {
    dispatch({ type: CLOSE_IMAGE_DIALOG });
  };
  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", imageShow);
    });
  });

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="responsive-dialog-title">Host Image</DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
            color: "#5E72E4",
          }}
        >
          <Tooltip title="Close">
            <Cancel onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="row" style={{ maxHeight: "550px", overFlow: "auto" }}>
            {imageDialogueData?.map((image) => {
              return (
                <>
                  <div className="col-12 col-md-4 my-4">
                    <img
                      src={image}
                      style={{
                        borderRadius: "12px",
                        width: "120px",
                        height: "120px",
                        display: "block",
                      }}
                      alt=""
                      className="mx-auto"
                    />
                  </div>
                </>
              );
            })}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ShowImageHostRequest;
