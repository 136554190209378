import axios from "axios";

import {
  GET_USER,
  BLOCK_UNBLOCK_USER,
  EDIT_USER,
  CLOSE_USER_DIALOG,
  SET_UPDATE_USER_DONE,
  GET_OFFLINE_TYPE,
} from "./types";

export const getUser = () => (dispatch) => {
  axios
    .get("/user")
    .then((res) => {
      dispatch({ type: GET_USER, payload: res.data.data });
    })
    .catch((error) => console.log(error));
};

export const blockUnblockUser = (id) => (dispatch) => {
  axios
    .get(`/user/${id}`)
    .then((res) => {
      dispatch({ type: BLOCK_UNBLOCK_USER, payload: res.data.data });
    })
    .catch((error) => console.log(error));
};

export const editUser = (formData, id) => (dispatch) => {
  axios
    .patch("/user/" + id, formData)
    .then((res) => {
      dispatch({
        type: EDIT_USER,
        payload: { data: res.data.user, id },
      });
      dispatch({ type: CLOSE_USER_DIALOG });
      dispatch({ type: SET_UPDATE_USER_DONE });
    })
    .catch((error) => console.log(error));
};

export const editCoin = (data) => (dispatch) => {
  axios
    .post(`/user/coin`, data)
    .then((res) => {
      console.log("res", res.data);

      dispatch({ type: GET_OFFLINE_TYPE, payload: res.data });

      // dispatch({ type: EDIT_USER, payload: res.data.user });
      // dispatch({ type: SET_UPDATE_USER_DONE });
    })
    .catch((error) => console.log(error));
};
