import dayjs from "dayjs";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

//Action
import { getAgencyWiseSettlement } from "../../store/agency/action";

//MUI
import { TablePagination } from "@material-ui/core";
import TablePaginationActions from "./TablePagination";
import ReactHtmlTableToExcel from "react-html-table-to-excel";

// //datepicker
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

//JQUERY
import $ from "jquery";

const AgencyWiseSettlementReport = (props) => {
  const location = useLocation();
  const [type, setType] = useState("week");
  const [date, setDate] = useState([]);
  const [sDate, setsDate] = useState("ALL");
  const [eDate, seteDate] = useState("ALL");

  const [value, onChange] = useState(new Date());

  //find current week start date
  const now_ = new Date().getTime();
  const now = new Date(now_ - (now_ % 86400000)); // get current date
  const currentWeekDay = now.getDay();
  const lessDays = currentWeekDay === 0 ? 6 : currentWeekDay - 1; // handle sunday to monday week start day
  const wkStart = new Date(new Date(now).setDate(now.getDate() - lessDays));

  useEffect(() => {
    props.getAgencyWiseSettlement(location.state.id, type, sDate, eDate);
  }, [type]);

  const { settlementReport } = useSelector((state) => state.agency);

  useEffect(() => {
    if (date.length === 0) {
      setDate([
        {
          startDate: wkStart,
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
    // $("#datePicker").removeClass("show");
    setData(settlementReport);
  }, [date]);

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(settlementReport);
  }, [settlementReport]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Handle Search Data
  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase();
    if (value) {
      const data_ = settlementReport.filter((data) => {
        return (
          data?.agencyName?.toUpperCase()?.indexOf(value) > -1 ||
          data?.name?.toUpperCase()?.indexOf(value) > -1 ||
          data?.country?.toUpperCase()?.indexOf(value) > -1
        );
      });
      setData(data_);
    } else {
      return setData(settlementReport);
    }
  };

  const collapsedDatePicker = () => {
    $("#datePicker").toggleClass("collapse");
  };

  //Apply button function for analytic
  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("YYYY-MM-DD") +
        " - " +
        picker.endDate.format("YYYY-MM-DD")
    );
    const dayStart = dayjs(picker.startDate).format("M/D/YYYY");

    const dayEnd = dayjs(picker.endDate).format("M/D/YYYY");

    props.getAgencyWiseSettlement(
      location.state.id,
      "custom",
      dayStart,
      dayEnd
    );
  };

  //Cancel button function for analytic
  const handleCancel = (event, picker) => {
    picker.element.val("");
    props.getAgencyWiseSettlement(location.state.id, "week", "ALL", "ALL");
  };

  return (
    <>
      <div class="page-breadcrumb">
        <div class="row">
          <div class="col-7 align-self-center">
            <div class="d-flex align-items-center">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0 p-0">
                  <li class="breadcrumb-item">
                    <Link to="/admin/dashboard" class="text-muted">
                      Home
                    </Link>
                  </li>
                  <li class="breadcrumb-item">
                    <Link to="/admin/agency" class="text-muted">
                      Agency
                    </Link>
                  </li>
                  <li
                    class="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Settlement Report
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div class="col-5  ">
            <ReactHtmlTableToExcel
              id="test-table-xls-button"
              className="btn btn-primary float-right"
              table="settlement-table"
              filename="SettlementTable"
              sheet="settlements"
              buttonText="Export To Excel"
            />
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8 mt-4 float-left">
                    <DateRangePicker
                      initialSettings={{
                        autoUpdateInput: false,
                        locale: {
                          cancelLabel: "Clear",
                        },
                        maxDate: new Date(),

                        buttonClasses: ["btn btn-dark"],
                      }}
                      onApply={handleApply}
                      onCancel={handleCancel}
                    >
                      <input
                        type="text"
                        class="daterange form-control float-left "
                        placeholder="Select Date"
                        style={{ width: 185, fontWeight: 700 }}
                      />
                    </DateRangePicker>
                    {/* <button
                      className="collapsed btn bg-purple-gradient text-white"
                      value="check"
                      data-toggle="collapse"
                      data-target="#datePicker"
                      onClick={collapsedDatePicker}
                      style={{
                        backgroundColor: "#5E72E4",
                        color: "white",
                      }}
                    >
                      Filter
                    </button>
                    <div
                      id="datePicker"
                      className="collapse mt-5 pt-5 position-absolute"
                      aria-expanded="false"
                    >
                      <div key={JSON.stringify(date)}>
                        <DateRangePicker
                          onChange={(item) => {
                            setDate([item.selection]);
                            const dayStart = dayjs(
                              item.selection.startDate
                            ).format("YYYY-MM-DD");
                            const dayEnd = dayjs(item.selection.endDate).format(
                              "YYYY-MM-DD"
                            );
                            // setPage(1);
                            setType("custom");
                            setsDate(dayStart);
                            seteDate(dayEnd);
                          }}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          ranges={date}
                          direction="horizontal"
                        />
                      </div>
                    </div> */}
                  </div>

                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-3 float-right">
                    <form action="">
                      <div class="input-group mb-4 border rounded-pill p-1">
                        <div class="input-group-prepend border-0">
                          <div
                            id="button-addon4"
                            class="btn btn-link text-primary"
                          >
                            <i class="fa fa-search"></i>
                          </div>
                        </div>
                        <input
                          type="search"
                          placeholder="What're you searching for?"
                          aria-describedby="button-addon4"
                          class="form-control bg-none border-0 rounded-pill mr-1"
                          onChange={handleSearch}
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8 mt-3 float-left mb-0"></div>
                </div>
                <div class="table-responsive">
                  <table
                    id="settlement-table"
                    class="table table-striped table-bordered no-wrap"
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Id No</th>
                        <th>Report To</th>
                        <th>Country</th>
                        <th>First Approved Date</th>
                        <th>Call Duration</th>
                        <th>Answer Rate</th>
                        <th>Video Chat Coins</th>
                        <th>Gifts</th>
                        <th>Bonus</th>
                        <th>Total Coin</th>
                        <th>Left Over In Last Week</th>
                        <th>Effective Coin</th>
                        <th>Anchor's Salary</th>
                        <th>Agency Commission</th>
                        <th>Agency's Salary</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? (
                        <>
                          {(rowsPerPage > 0
                            ? data.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : data
                          ).map((data, index) => {
                            return (
                              <tr key={index}>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.name}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.username}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.agencyName}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.country}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {dayjs(data.approvedDate).format(
                                    "DD MMM, YYYY"
                                  )}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.videoCallDuration + " sec"}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data?.totalHostVideoCallAnswerRate > 0
                                    ? (
                                        (data?.videoCallLessDuration /
                                          data?.totalHostVideoCallAnswerRate) *
                                        100
                                      ).toFixed(2) + "%"
                                    : 0}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.coinHistory}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.totalGiftIncome}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.bonus}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.totalCoin}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.leftOverCoin}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.effectiveCoin.toFixed(2)}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.anchorSalary.toFixed(2)}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {`${data.agencyCommission}%`}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.agencySalary.toFixed(2)}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.totalAgencySalary.toFixed(2)}
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : (
                        <tr>
                          <td colSpan="15" align="center">
                            Nothing to show!!
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Name</th>
                        <th>Id No</th>
                        <th>Report To</th>
                        <th>Country</th>
                        <th>First Approved Date</th>
                        <th>Call Duration</th>
                        <th>Answer Rate</th>
                        <th>Video Chat Coins</th>
                        <th>Gifts</th>
                        <th>Bonus</th>
                        <th>Total Coin</th>
                        <th>Left Over In Last Week</th>
                        <th>Effective Coin</th>
                        <th>Anchor's Salary</th>
                        <th>Agency Commission</th>
                        <th>Agency's Salary</th>
                        <th>Total</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="py-2">
                  <TablePagination
                    id="pagination"
                    component="div"
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    count={data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAgencyWiseSettlement })(
  AgencyWiseSettlementReport
);
