import React, { Fragment, useEffect, useState } from "react";

//router
import { Link, useHistory } from "react-router-dom";

//alert
import { alert, warning } from "../../util/alert";

//server path
import { baseURL } from "../../util/serverPath";

//dialog
import HostDialog from "../Dialog/hostDialog";

//dayjs
import dayjs from "dayjs";

import ReactHTMLTableToExcel from "react-html-table-to-excel";

//redux
import { useDispatch, connect, useSelector } from "react-redux";
import {
  FakeHostList,
  blockUnblockHost,
  live,
  video,
  deleteFakeHost,
} from "../../store/host/action";

import {
  OPEN_HOST_DIALOG,
  UNSET_CREATE_HOST_DONE,
  UNSET_UPDATE_HOST_DONE,
  GET_SINGLE_HOST_DATA,
} from "../../store/host/types";

//custom css
import "../../dist/css/style.min.css";
import "../../dist/css/style.css";

//MUI
import { Snackbar, TablePagination } from "@material-ui/core";
import TablePaginationActions from "./TablePagination";
import { Alert } from "@material-ui/lab";

// //datepicker
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
// import BoatHostDialog from "../Dialog/BoatHostDialog";

const FakeHostTable = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openUpdateSuccess, setOpenUpdateSuccess] = useState(false);

  const [status, setStatus] = useState("");

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const { fakeHost, createDone, updateDone } = useSelector(
    (state) => state.host
  );
 

  useEffect(() => {
    props.FakeHostList();
  }, []);

  useEffect(() => {
    setData(fakeHost);
  }, [fakeHost]);

  const setLive = (data) => {
    
    props.live(data._id);
  };
  const setVideo = (data) => {
    
    props.video(data._id);
  };

  const handleOpen = () => {
    dispatch({ type: OPEN_HOST_DIALOG });
  };

  const handleEdit = (data) => {
    dispatch({ type: OPEN_HOST_DIALOG, payload: data });
  };

  useEffect(() => {
    if (createDone) {
      setOpenSuccess(true);
      dispatch({ type: UNSET_CREATE_HOST_DONE });
    }
  }, [createDone, dispatch]);
  useEffect(() => {
    if (updateDone) {
      setOpenUpdateSuccess(true);
      dispatch({ type: UNSET_UPDATE_HOST_DONE });
    }
  }, [updateDone, dispatch]);

  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase();
    if (value) {
      const data = fakeHost.filter((data) => {
        return (
          data?.name?.toUpperCase()?.indexOf(value) > -1 ||
          data?.uniqueId?.toUpperCase()?.indexOf(value) > -1 ||
          data?.country?.toUpperCase()?.indexOf(value) > -1 ||
          data?.agencyId?.name?.toUpperCase()?.indexOf(value) > -1 ||
          data?.bio?.toUpperCase()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      return setData(fakeHost);
    }
  };

  //   const handleAnalytic = (data) => {
  //     dispatch({ type: GET_SINGLE_HOST_DATA, payload: data });
  //     history.push("/admin/host/analytic/" + data._id);
  //   };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };
  const handleCloseUpdateSuccess = () => {
    setOpenUpdateSuccess(false);
  };

  useEffect(() => {
    if (status === "online") {
      const data = fakeHost.filter((data) => data.isOnline);
      setData(data);
    } else if (status === "live") {
      const data = fakeHost.filter((data) => data.isLive);
      setData(data);
    } else {
      return setData(fakeHost);
    }
  }, [status]);

  //Handle Ope Host Info
  const handleHostInfo = (data) => {
    localStorage.setItem("Host_Info", JSON.stringify(data));

    history.push({
      pathname: "/admin/allHost/hostInfo",
      state: { host: data },
    });
  };

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteFakeHost(id);
          alert("Deleted!", `Banner Photos has been deleted!`, "success");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Fragment>
      <Snackbar
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSuccess} severity="success">
          <span style={{ color: "#184d47" }}>
            <b>Success!</b> Host add successfully.
          </span>
        </Alert>
      </Snackbar>
      <Snackbar
        open={openUpdateSuccess}
        autoHideDuration={3000}
        onClose={handleCloseUpdateSuccess}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseUpdateSuccess} severity="success">
          <span style={{ color: "#184d47" }}>
            <b>Success!</b> Host update successfully.
          </span>
        </Alert>
      </Snackbar>
      <div class="page-breadcrumb">
        <div class="row">
          <div class="col-7 align-self-center">
            <div class="d-flex align-items-center">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0 p-0">
                  <li class="breadcrumb-item">
                    <Link to="/admin/dashboard" class="text-muted">
                      Home
                    </Link>
                  </li>
                  <li class="breadcrumb-item">
                    <Link to="/admin/host/agency" class="text-muted">
                      Agency
                    </Link>
                  </li>
                  <li
                    class="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Host
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8 mt-4 float-left">
                    <div className="row">
                      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-3 float-left">
                        <button
                          type="button"
                          class="btn waves-effect waves-light btn-primary btn-sm"
                          data-toggle="modal"
                          // data-target="#country-modal"
                          style={{ borderRadius: 5 }}
                          onClick={handleOpen}
                        >
                          <i class="fas fa-plus"></i> New
                        </button>
                      </div>
                      {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-2">
                        <div>
                          <select
                            class="form-select form-control"
                            aria-label="Default select example"
                            value={status}
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                            style={{ width: 185, fontWeight: 700 }}
                          >
                            <option selected>Status</option>
                            <option value="online">Online</option>
                            <option value="live">Live</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-xs-3 mt-2">
                        <DateRangePicker
                          initialSettings={{
                            autoUpdateInput: false,
                            locale: {
                              cancelLabel: "Clear",
                            },
                            maxDate: new Date(),

                            buttonClasses: ["btn btn-dark"],
                          }}
                          onApply={handleApply}
                          onCancel={handleCancel}
                        >
                          <input
                            type="text"
                            class="daterange form-control float-left "
                            placeholder="Select Date"
                            style={{ width: 185, fontWeight: 700 }}
                          />
                        </DateRangePicker>
                      </div> */}
                    </div>
                    {/* <h3 class="card-title">Host</h3> */}
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-3 float-right mt-2">
                    <form action="">
                      <div class="input-group mb-4 border rounded-pill p-1">
                        <div class="input-group-prepend border-0">
                          <div
                            id="button-addon4"
                            class="btn btn-link text-primary"
                          >
                            <i class="fa fa-search"></i>
                          </div>
                        </div>
                        <input
                          type="search"
                          placeholder="What're you searching for?"
                          aria-describedby="button-addon4"
                          class="form-control bg-none border-0 rounded-pill mr-1"
                          onChange={handleSearch}
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8 mt-3 float-left mb-0"></div>
                </div>

                <div class="table-responsive">
                  <table
                    id="zero_config"
                    class="table table-striped table-bordered no-wrap"
                  >
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Host Id</th>
                        {/* <th>Status</th>
                        <th>Online Duration</th>
                        <th>Average Time</th>
                        <th>Live Duration</th>
                        <th>Unqualified Rate</th>
                        <th>Answer Rate</th> */}
                        <th>Coin</th>
                        <th>Country</th>
                        <th>Agency Name</th>
                        <th>Live</th>
                        <th>Video</th>
                        <th>Action</th>
                        {/* <th>Host Info</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? (
                        <Fragment>
                          {(rowsPerPage > 0
                            ? data.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : data
                          ).map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {
                                    <img
                                      src={data?.image[0]}
                                      width="70px"
                                      height="70px"
                                      alt="img"
                                      style={{
                                        objectFit: "contain",
                                        borderRadius: "50%",
                                        border: " 1px solid #808080",
                                      }}
                                      class="mr-3"
                                    />
                                  }
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data?.name}
                                </td>

                                <td style={{ verticalAlign: "middle" }}>
                                  {data?.uniqueId}
                                </td>
                                {/* <td style={{ verticalAlign: "middle" }}>
                                  {data?.isLive ? (
                                    <span className="text-success font-weight-bold">
                                      Live
                                    </span>
                                  ) : data?.isOnline ? (
                                    <span className="text-success font-weight-bold">
                                      Online
                                    </span>
                                  ) : (
                                    <span className="text-danger font-weight-bold">
                                      Offline
                                    </span>
                                  )}
                                </td>

                                <td style={{ verticalAlign: "middle" }}>
                                  {Math.ceil(data?.onlineDuration / 60) +
                                    " Minute"}
                                </td>

                              
                                <td style={{ verticalAlign: "middle" }}>
                                  {data?.totalHostVideoCall > 0
                                    ? (
                                        Math.ceil(
                                          data?.videoCallDuration /
                                            data?.totalHostVideoCall
                                        ) / 60
                                      ).toFixed(2) + " Minute"
                                    : 0}
                                </td>

                                <td style={{ verticalAlign: "middle" }}>
                                  {Math.ceil(data?.liveHistory / 60) +
                                    " Minute"}
                                </td>

                                
                                <td style={{ verticalAlign: "middle" }}>
                                  {data?.videoCallAllAnsweredCall > 0
                                    ? (
                                        (
                                          data?.videoCallLessDuration /
                                          data?.videoCallAllAnsweredCall
                                        ).toFixed(2) * 100
                                      ).toFixed(2) + "%"
                                    : 0}
                                </td>

                                <td style={{ verticalAlign: "middle" }}>
                                  {data?.allStatusVideoCall > 0
                                    ? (
                                        (data?.hostCallAnswer /
                                          data?.allStatusVideoCall) *
                                        100
                                      ).toFixed(2) + "%"
                                    : 0}
                                </td> */}
                                <td style={{ verticalAlign: "middle" }}>
                                  {data?.coin}
                                </td>

                                <td style={{ verticalAlign: "middle" }}>
                                  {data?.country}
                                </td>

                                <td style={{ verticalAlign: "middle" }}>
                                  {data?.agencyId?.name}
                                </td>

                                <td
                                  align="center"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      checked={data?.showInLive}
                                      onChange={() => setLive(data)}
                                    />
                                    <span class="slider">
                                      <p
                                        style={{
                                          fontSize: 12,
                                          marginLeft: `${
                                            data?.showInLive ? "-25px" : "25px"
                                          }`,
                                          color: "white",
                                          marginTop: "6px",
                                        }}
                                      >
                                        {data?.showInLive ? "Yes" : "No"}
                                      </p>
                                    </span>
                                  </label>
                                </td>
                                <td
                                  align="center"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      checked={data?.showInVideo}
                                      onChange={() => setVideo(data)}
                                    />
                                    <span class="slider">
                                      <p
                                        style={{
                                          fontSize: 12,
                                          marginLeft: `${
                                            data?.showInVideo ? "-25px" : "25px"
                                          }`,
                                          color: "white",
                                          marginTop: "6px",
                                        }}
                                      >
                                        {data?.showInVideo ? "Yes" : "No"}
                                      </p>
                                    </span>
                                  </label>
                                </td>

                                <td style={{ verticalAlign: "middle" }}>
                                  <a
                                    href={() => false}
                                    class="ml-3"
                                    onClick={() => handleEdit(data)}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <i class="fas fa-edit text-primary mr-3"></i>
                                  </a>
                                  <a
                                    onClick={() => handleDelete(data._id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <i class="fas fa-trash text-danger mr-3"></i>
                                  </a>
                                </td>
                                {/* <td style={{ verticalAlign: "middle" }}>
                                  <button
                                    type="button"
                                    class="btn btn-fill btn-primary btn-sm"
                                    style={{
                                      borderRadius: 5,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleHostInfo(data)}
                                  >
                                    <i class="fas fa-info-circle text-white pr-2"></i>
                                    Host Info
                                  </button>
                                </td> */}
                              </tr>
                            );
                          })}
                        </Fragment>
                      ) : (
                        <tr>
                          <td colSpan="12" align="center">
                            Nothing to show!!
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Host Id</th>
                        {/* <th>Status</th>
                        <th>Online Duration</th>
                        <th>Average Time</th>
                        <th>Live Duration</th>
                        <th>Unqualified Rate</th>
                        <th>Answer Rate</th> */}
                        <th>Coin</th>
                        <th>Country</th>
                        <th>Agency Name</th>
                        <th>Live</th>
                        <th>Video</th>
                        <th>Action</th>

                        {/* <th>Host Info</th> */}
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="py-2">
                  <TablePagination
                    id="pagination"
                    component="div"
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    count={data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HostDialog />
      {/* <BoatHostDialog /> */}
    </Fragment>
  );
};

export default connect(null, {
  FakeHostList,
  blockUnblockHost,
  live,
  video,
  deleteFakeHost,
})(FakeHostTable);
